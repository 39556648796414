import { subDays } from "date-fns";
import { Response, SummaryResponse } from "..";

export const emptySummaryResponse: Response<SummaryResponse> = {
  startDateUtc: subDays(new Date(), 14),
  endDateUtc: new Date(),
  result: {
    buyingSignalsCount: 0,
    hasMailLetters: true,
    pendingSalesCount: 0,
    openShowroomVisitsCount: 0,
    responseTimes: [],
    unAnsweredCommunicationCount: 0,
    salesFunnelCounts: {
      allCustomers: 0,
      contactedCustomers: 0,
      appointmentCustomers: 0,
      appointmentCustomersCompleted: 0,
      soldCustomers: 0
    }
  }
};
