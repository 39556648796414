import { BaseUrlsObj, envEnum } from "@vinsolutions/core/environment";

type Config = {
  baseUrls: BaseUrlsObj;
};

export const DealApiConfig: Config = {
  baseUrls: {
    [envEnum.LOCAL]: "https://workflow-dev.np.vinsolutions.com/api/lead/",
    [envEnum.DEV]: "https://workflow-dev.np.vinsolutions.com/api/lead/",
    [envEnum.QA]: "https://workflow-qa.np.vinsolutions.com/api/lead/",
    [envEnum.STAGING]: "https://workflow-staging.np.vinsolutions.com/api/lead/",
    [envEnum.PRODUCTION]: "https://workflow.vinsolutions.com/api/lead/"
  }
};
