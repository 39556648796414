/* eslint-disable @cspell/spellchecker */
import { TagManager } from "@vinsolutions/core-third-party-gtm";
import { DealApplicationEventParams } from "./deal-application-event";

const sendLaunchApplicationAnalytics = (applicationName: string) => {
  TagManager.event({
    event: "userEvent",
    eventElement: "Accelerate Banner Modal",
    eventAction: `${applicationName} button clicked`,
    eventResult: `Opened ${applicationName}`,
    eventLocation: `Customer Dashboard > "Accelerate Banner Modal" > ${applicationName}`
  });
};

export const LaunchUrlEvent = function (params: DealApplicationEventParams) {
  return new Promise<void>(() => {
    sendLaunchApplicationAnalytics(params.applicationName);

    if (!params.applicationUrl) {
      return;
    }
    const win = window.open(params.applicationUrl, "_blank");
    if (win) {
      win.focus();
    }
  });
};
