import {
  ERROR_MSG,
  SUCCESS_MSG_ON_DELETE,
  SUCCESS_MSG_ON_SAVE
} from "../../constants";
import { createSlice } from "@reduxjs/toolkit";
import { generateId } from "../../helpers";
import {
  createRuleSet,
  deleteRuleSetByOem,
  fetchAllRuleSets,
  updateRuleSet
} from "./authSettings.thunk";
import {
  AuthSettingsErrors,
  AuthSettingsModalType,
  AuthSettingsStateType,
  EnrichedAuthenticationRule,
  EnrichedConductorRuleSet,
  EnrichedPermission
} from "../../types/authSettings.type";
import {
  AuthenticationRuleDetails,
  AuthenticationRuleEnablement,
  AuthenticationType,
  ConductorRuleSet
} from "@vinsolutions/data-access/oem/coxauto.lead.oem.api";

const defaultConductorRuleSet: EnrichedConductorRuleSet = {
  key: generateId(),
  id: "",
  oemSource: "",
  authenticationRules: [],
  permissions: []
};

const defaultPermission: EnrichedPermission = {
  key: generateId(),
  parentId: "",
  action: "",
  allowed: false
};

const defaultAuthenticationRule: EnrichedAuthenticationRule = {
  key: generateId(),
  parentId: "",
  authenticationType: AuthenticationType.OAUTH2,
  details: {} as AuthenticationRuleDetails,
  enablement: {} as AuthenticationRuleEnablement
};

const defaultValidationErrors: AuthSettingsErrors = {
  authenticationRulesHasErrors: false,
  permissionsHasErrors: false,
  conductorRuleSetHasErrors: false,
  conductorRuleSet: {},
  authenticationRules: {},
  permissions: {}
};

const initialState: AuthSettingsStateType = {
  isUnauthorized: false,
  isAddNewOem: false,
  conductorRuleSets: [],
  conductorRuleSet: defaultConductorRuleSet,
  authenticationRule: defaultAuthenticationRule,
  permission: defaultPermission,
  validationErrors: defaultValidationErrors,
  isAuthSettingsLoading: true,
  toastContext: { isSuccessful: false, message: "" },
  modalContext: { isModalOpen: false, isApiLoading: false }
};

const addKeyToRuleSet = (
  ruleSet: ConductorRuleSet
): EnrichedConductorRuleSet => ({
  ...ruleSet,
  key: ruleSet.id,
  authenticationRules: ruleSet.authenticationRules.map(rule => ({
    ...rule,
    parentId: ruleSet.id,
    key: generateId()
  })),
  permissions: ruleSet.permissions.map(permission => ({
    ...permission,
    parentId: ruleSet.id,
    key: generateId()
  }))
});

export const AUTH_SETTINGS_FEATURE_KEY = "auth-settings";

export const authSettingsSlice = createSlice({
  name: AUTH_SETTINGS_FEATURE_KEY,
  initialState,
  reducers: {
    setAddNewOem: (state, { payload: isAddNewOem }: { payload: boolean }) => {
      state.isAddNewOem = isAddNewOem;
    },
    setOemSource: (state, { payload: oemSource }: { payload: string }) => {
      state.conductorRuleSet.oemSource = oemSource;
    },
    setConductorRuleSet: (
      state,
      { payload: conductorRuleSet }: { payload: EnrichedConductorRuleSet }
    ) => {
      state.conductorRuleSet = conductorRuleSet;
      state.modalContext.isModalOpen = false;
    },
    setPermission: (
      state,
      { payload: permission }: { payload: EnrichedPermission }
    ) => {
      state.permission = permission;
    },
    setAuthenticationRule: (
      state,
      { payload: authenticationRule }: { payload: EnrichedAuthenticationRule }
    ) => {
      state.authenticationRule = authenticationRule;
    },
    setModalContent: (
      state,
      {
        payload: { isModalOpen = false, primary, content, header }
      }: { payload: AuthSettingsModalType }
    ) => {
      state.modalContext = {
        isModalOpen,
        primary,
        content,
        header
      };
    },
    setErrors: (
      state,
      {
        payload: validationErrors
      }: {
        payload: AuthSettingsErrors;
      }
    ) => {
      state.validationErrors = {
        ...state.validationErrors,
        ...validationErrors
      };
    },
    clearErrorByKey: (
      state,
      {
        payload: { fieldName, key }
      }: {
        payload: {
          fieldName: "permissions" | "authenticationRules" | "conductorRuleSet";
          key: string;
        };
      }
    ) => {
      if (state.validationErrors[fieldName]) {
        if (key === "*") {
          state.validationErrors[fieldName] = {};
          state.validationErrors[`${fieldName}HasErrors`] = false;
        } else {
          delete state.validationErrors[fieldName]?.[key];

          if (
            Object.keys(state.validationErrors[fieldName] ?? {}).length === 0
          ) {
            state.validationErrors[`${fieldName}HasErrors`] = false;
          }
        }
      }
    }
  },
  extraReducers: builder => {
    builder.addCase(fetchAllRuleSets.pending, state => {
      state.isAuthSettingsLoading = true;
    });

    builder.addCase(fetchAllRuleSets.rejected, (state, payload) => {
      if (payload.error.message === "Unauthorized") {
        state.isUnauthorized = true;
      } else {
        state.toastContext = {
          isSuccessful: false,
          message: ERROR_MSG
        };
      }

      state.isAuthSettingsLoading = false;
    });

    builder.addCase(
      fetchAllRuleSets.fulfilled,
      (state, { payload: conductorRuleSets }) => {
        state.conductorRuleSets = conductorRuleSets.map(addKeyToRuleSet);
        state.isAuthSettingsLoading = false;
      }
    );

    builder.addCase(updateRuleSet.pending, state => {
      if (state.modalContext) {
        state.modalContext.isApiLoading = true;
      }
    });

    builder.addCase(updateRuleSet.rejected, (state, payload) => {
      state.modalContext.isApiLoading = false;
      state.modalContext.isModalOpen = false;
      state.toastContext = {
        isSuccessful: false,
        message: ERROR_MSG
      };
    });

    builder.addCase(
      updateRuleSet.fulfilled,
      (
        state,
        { payload: conductorRuleSet }: { payload: EnrichedConductorRuleSet }
      ) => {
        if (state.conductorRuleSets) {
          const ruleSetIndex = state.conductorRuleSets.findIndex(
            ruleSet => ruleSet.id === conductorRuleSet.id
          );

          if (ruleSetIndex > -1) {
            state.conductorRuleSets[ruleSetIndex] = conductorRuleSet;
          }
        }

        state.modalContext.isApiLoading = false;
        state.modalContext.isModalOpen = false;
        state.toastContext = {
          isSuccessful: true,
          message: SUCCESS_MSG_ON_SAVE
        };
      }
    );

    builder.addCase(deleteRuleSetByOem.pending, state => {
      if (state.modalContext) {
        state.modalContext.isApiLoading = true;
      }
    });

    builder.addCase(deleteRuleSetByOem.rejected, state => {
      state.modalContext.isApiLoading = false;
      state.modalContext.isModalOpen = false;
      state.toastContext = {
        isSuccessful: false,
        message: ERROR_MSG
      };
    });

    builder.addCase(deleteRuleSetByOem.fulfilled, (state, { payload: oem }) => {
      state.conductorRuleSets = state.conductorRuleSets?.filter(
        (ruleSet: EnrichedConductorRuleSet) =>
          ruleSet.oemSource.toString() !== oem
      );

      state.modalContext.isApiLoading = false;
      state.modalContext.isModalOpen = false;
      state.toastContext = {
        isSuccessful: true,
        message: SUCCESS_MSG_ON_DELETE
      };
    });

    builder.addCase(createRuleSet.pending, state => {
      state.isAuthSettingsLoading = true;
    });

    builder.addCase(createRuleSet.rejected, state => {
      state.isAuthSettingsLoading = false;
      state.toastContext = {
        isSuccessful: false,
        message: ERROR_MSG
      };
    });

    builder.addCase(
      createRuleSet.fulfilled,
      (
        state,
        { payload: conductorRuleSet }: { payload: EnrichedConductorRuleSet }
      ) => {
        state.isAuthSettingsLoading = false;
        state.isAddNewOem = false;
        state.authenticationRule = defaultAuthenticationRule;
        state.permission = defaultPermission;
        state.conductorRuleSet = defaultConductorRuleSet;
        state.conductorRuleSets.push(conductorRuleSet);
        state.toastContext = {
          isSuccessful: true,
          message: SUCCESS_MSG_ON_SAVE
        };
      }
    );
  }
});

export const {
  setErrors,
  setOemSource,
  setAddNewOem,
  setPermission,
  setModalContent,
  setConductorRuleSet,
  setAuthenticationRule,
  clearErrorByKey
} = authSettingsSlice.actions;

export const authSettingsReducer = authSettingsSlice.reducer;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getAuthSettingsState = (rootState: any): AuthSettingsStateType =>
  rootState[AUTH_SETTINGS_FEATURE_KEY];
