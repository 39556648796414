import { useCallback, useEffect } from "react";
import { useOverlay } from "../context/overlay-context";
import { OverlayPosition } from "../enums/overlay-position";
import VinConnectOverlayEventChannels from "../interfaces/vinconnect-overlay-event-channels";

/**
 * Custom hook to set up event listeners for overlay events.
 * This hook integrates with the overlay context to handle opening, closing,
 * switching positions, and toggling the size of overlays based on custom events.
 *
 * @example
 * useOverlayEvents();
 */
const useOverlayEvents = () => {
  const {
    openOverlay,
    closeOverlay,
    switchOverlayPosition,
    toggleOverlaySize
  } = useOverlay();

  /**
   * Handles the open overlay event.
   *
   * @param {CustomEvent<{ name: string; position: OverlayPosition; params?: any }>} event - The custom event containing the overlay name, position, and additional parameters.
   */
  const handleOpenOverlay = useCallback(
    (
      event: CustomEvent<{
        name: string;
        position: OverlayPosition;
        params?: any;
      }>
    ) =>
      openOverlay(
        event.detail.name,
        event.detail.position,
        event.detail.params
      ),
    [openOverlay]
  );

  /**
   * Handles the close overlay event.
   *
   * @param {CustomEvent<{ name: string }>} event - The custom event containing the overlay name.
   */
  const handleCloseOverlay = useCallback(
    (event: CustomEvent<{ name: string }>) => closeOverlay(event.detail.name),
    [closeOverlay]
  );

  /**
   * Handles the switch overlay position event.
   *
   * @param {CustomEvent<{ name: string }>} event - The custom event containing the overlay name.
   */
  const handleSwitchOverlayPosition = useCallback(
    (event: CustomEvent<{ name: string }>) =>
      switchOverlayPosition(event.detail.name),
    [switchOverlayPosition]
  );

  /**
   * Handles the toggle overlay size event.
   *
   * @param {CustomEvent<{ name: string }>} event - The custom event containing the overlay name.
   */
  const handleToggleOverlaySize = useCallback(
    (event: CustomEvent<{ name: string }>) =>
      toggleOverlaySize(event.detail.name),
    [toggleOverlaySize]
  );

  useEffect(() => {
    window.openOverlay = openOverlay;
    window.closeOverlay = closeOverlay;
    window.switchOverlayPosition = switchOverlayPosition;
    window.toggleOverlaySize = toggleOverlaySize;

    window.addEventListener(
      VinConnectOverlayEventChannels.openOverlay,
      handleOpenOverlay as EventListener,
      true
    );
    window.addEventListener(
      VinConnectOverlayEventChannels.closeOverlay,
      handleCloseOverlay as EventListener,
      true
    );
    window.addEventListener(
      VinConnectOverlayEventChannels.switchOverlayPosition,
      handleSwitchOverlayPosition as EventListener,
      true
    );
    window.addEventListener(
      VinConnectOverlayEventChannels.toggleOverlaySize,
      handleToggleOverlaySize as EventListener,
      true
    );

    return () => {
      window.removeEventListener(
        VinConnectOverlayEventChannels.openOverlay,
        handleOpenOverlay as EventListener,
        true
      );
      window.removeEventListener(
        VinConnectOverlayEventChannels.closeOverlay,
        handleCloseOverlay as EventListener,
        true
      );
      window.removeEventListener(
        VinConnectOverlayEventChannels.switchOverlayPosition,
        handleSwitchOverlayPosition as EventListener,
        true
      );
      window.removeEventListener(
        VinConnectOverlayEventChannels.toggleOverlaySize,
        handleToggleOverlaySize as EventListener,
        true
      );
    };
  }, [
    openOverlay,
    closeOverlay,
    switchOverlayPosition,
    toggleOverlaySize,
    handleOpenOverlay,
    handleCloseOverlay,
    handleSwitchOverlayPosition,
    handleToggleOverlaySize
  ]);
};

export default useOverlayEvents;
