import {
  BaseUrlsObj,
  envEnum,
  getBaseUrl
} from "@vinsolutions/core/environment";
import { fetchWrapper } from "@vinsolutions/core/http";
import { WorkflowGatewayConfig } from "./crm.workflow.api";
import { createLogger } from "@vinsolutions/logger";
import { DealKeyInformation } from "./models/deal-key-information";

const logger = createLogger("data-access-lead-crm.workflow.keyinfo.accelerate");

type Config = {
  baseUrls: BaseUrlsObj;
};

const config: Config = {
  baseUrls: {
    [envEnum.LOCAL]: `http://localhost:5146/v1/mock/keyinfo/accelerate/dealsummary/`,
    [envEnum.DEV]: `${WorkflowGatewayConfig.baseUrls.dev}keyinfo/accelerate/dealsummary/`,
    [envEnum.QA]: `${WorkflowGatewayConfig.baseUrls.qa}keyinfo/accelerate/dealsummary/`,
    [envEnum.STAGING]: `${WorkflowGatewayConfig.baseUrls.staging}keyinfo/accelerate/dealsummary/`,
    [envEnum.PRODUCTION]: `${WorkflowGatewayConfig.baseUrls.production}keyinfo/accelerate/dealsummary/`
  }
};

export async function getDealKeyInformation(
  customerId: number,
  leadId: number,
  jwt: string
): Promise<DealKeyInformation | null | undefined> {
  if (!customerId) {
    throw new Error("Invalid customerId");
  }
  if (!leadId) {
    throw new Error("Invalid leadId");
  }

  const dealSummaryUrl = getDealKeyInformationUrl(customerId, leadId);
  const response = await fetchWrapper.getAsync<DealKeyInformation>(
    dealSummaryUrl,
    {
      Authorization: `Bearer ${jwt}`,
      Accept: "application/vnd.coxauto.v1+json"
    }
  );

  if (response.statusCode !== 200) {
    logger.error("Error getting deal summary", JSON.stringify(response));
    return null;
  }

  return response.data;
}

export function getDealKeyInformationUrl(
  customerId: number,
  leadId: number
): string {
  const baseUrl = getBaseUrl(config.baseUrls);
  return `${baseUrl}customer/id/${customerId}?leadId=${leadId}`;
}
