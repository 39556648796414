import { TableColumns } from "@interstate/components/Table";
import { PencilSquareIcon } from "@interstate/components/Icons";
import { AuthSettingsBadge } from "../auth-settings-badge/authSettingsBadge.component";
import { EnrichedPermission } from "../../types/authSettings.type";
import { AuthSettingsPermissionsActionButton } from "./authSettingsPermissionsActionButton.component";
import { AuthSettingsPermissionsDeleteButton } from "./authSettingsPermissionsDeleteButton.component";

export const PermissionsTableColumnsConfig: TableColumns = [
  {
    fixed: "left",
    width: 110,
    render: (_: unknown, record: EnrichedPermission) => {
      return (
        <>
          <AuthSettingsPermissionsActionButton
            buttonIcon={<PencilSquareIcon />}
            initialPermission={record}
            isEditMode={true}
          />
          <AuthSettingsPermissionsDeleteButton permission={record} />
        </>
      );
    }
  },
  {
    width: 100,
    title: "Status",
    dataIndex: "allowed",
    render: (_: unknown, record: EnrichedPermission) => {
      return <AuthSettingsBadge disabled={!record.allowed} />;
    }
  },
  {
    title: "Action",
    dataIndex: "action",
    render: (_: unknown, record: EnrichedPermission) => {
      return <label>{record.action}</label>;
    }
  }
];
