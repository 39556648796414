import { useEffect } from "react";
import { useSelector } from "react-redux";
import { Table } from "@interstate/components/Table";
import { useAuthSettingsApp } from "../../hooks/useAuthSettings.hook";
import { getAuthSettingsState } from "../../slices/auth-settings";
import { AuthSettingsAccordionGroup } from "../auth-settings-accordion-group/authSettingsAccordionGroup.component";
import { AuthSettingsTableColumnsConfig } from "./authSettings.config";
import { AuthSettingsAddButton } from "./authSettingsAddButton.component";
import { AuthSettingsUnauthorized } from "../auth-settings-unauthorized/authSettingUnauthorized.component";

export const AuthSettings = () => {
  const { getAllRuleSets } = useAuthSettingsApp();
  const { conductorRuleSets, isAuthSettingsLoading, isUnauthorized } =
    useSelector(getAuthSettingsState);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => getAllRuleSets(), []);

  return isUnauthorized ? (
    <AuthSettingsUnauthorized />
  ) : (
    <>
      <AuthSettingsAddButton />
      <Table
        displayFilter
        columns={AuthSettingsTableColumnsConfig}
        data={conductorRuleSets}
        data-testid="oem-auth-settings-table"
        displayHeader={false}
        emptyText="Not auth settings found."
        expandRowOnClick={false}
        expandedRowComponent={AuthSettingsAccordionGroup}
        filterInputPlaceholder="Search OEMs..."
        headerBackgroundColor="light"
        id="oem-auth-settings-table"
        isLoading={isAuthSettingsLoading}
        scrollY={window.innerHeight - 340}
        sortableDetails={{
          activeSortColumn: "oemSource",
          sortOrder: "ascend"
        }}
      />
    </>
  );
};
