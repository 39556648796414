enum ReportType {
  NONE = 0,
  KPI_OPEN_VISITS = 1,
  KPI_PENDING_SALES = 2,
  KPI_UNANSWERED_COMMUNICATIONS = 3,
  KPI_BUYING_SIGNALS = 14,
  RESPONSE_TIME_ALL = 13,
  RESPONSE_TIME_MISSED = 4,
  RESPONSE_TIME_NO_CONTACT = 5,
  RESPONSE_TIME_TARGET1 = 6,
  RESPONSE_TIME_TARGET2 = 7,
  SALES_FUNNEL_CUSTOMERS = 8,
  SALES_FUNNEL_CONTACTED = 9,
  SALES_FUNNEL_APPOINTMENTS_SET = 10,
  SALES_FUNNEL_APPOINTMENTS_SHOWN = 11,
  SALES_FUNNEL_SOLD = 12
}

export const salesFunnelReportTypes = [
  ReportType.SALES_FUNNEL_APPOINTMENTS_SET,
  ReportType.SALES_FUNNEL_APPOINTMENTS_SHOWN,
  ReportType.SALES_FUNNEL_CONTACTED,
  ReportType.SALES_FUNNEL_CUSTOMERS,
  ReportType.SALES_FUNNEL_SOLD
];

export const responseTimeTargets = new Map<ReportType, string>([
  [ReportType.RESPONSE_TIME_TARGET1, "g1"],
  [ReportType.RESPONSE_TIME_TARGET2, "g2"],
  [ReportType.RESPONSE_TIME_NO_CONTACT, "gn"],
  [ReportType.RESPONSE_TIME_MISSED, "go"]
]);

export default ReportType;
