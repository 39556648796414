import { AuthenticationRuleDetails } from "@vinsolutions/data-access/oem/coxauto.lead.oem.api";
import {
  AuthSettingsErrors,
  EnrichedAuthenticationRule
} from "../types/authSettings.type";

export const validateConductorAuthenticationRule = (
  authenticationRule: EnrichedAuthenticationRule
): AuthSettingsErrors => {
  const errors: Record<string, string[]> = {};
  const authenticationRules: AuthSettingsErrors = {
    authenticationRules: errors,
    authenticationRulesHasErrors: false
  };

  validateAuthenticationRuleDetails(
    authenticationRule.details,
    errors,
    authenticationRules
  );
  validateAuthenticationType(authenticationRule, errors, authenticationRules);

  return authenticationRules;
};

const validateAuthenticationRuleDetails = (
  { title, description }: AuthenticationRuleDetails,
  errors: Record<string, string[]>,
  authenticationRules: AuthSettingsErrors
) => {
  if (!title) {
    addError(errors, "title", "Title is required.", authenticationRules);
  } else if (title.length > 50) {
    addError(
      errors,
      "title",
      "Title must not exceed 50 characters.",
      authenticationRules
    );
  }

  if (description !== undefined && description.length > 500) {
    addError(
      errors,
      "description",
      "Description must not exceed 500 characters.",
      authenticationRules
    );
  }
};

const validateAuthenticationType = (
  {
    authenticationType,
    basicAuthDetails,
    oAuth2Details,
    apiKeyAuthDetails
  }: EnrichedAuthenticationRule,
  errors: Record<string, string[]>,
  authenticationRules: AuthSettingsErrors
) => {
  switch (authenticationType) {
    case "Basic":
      validateBasicAuthDetails(basicAuthDetails, errors, authenticationRules);
      break;
    case "OAuth2":
      validateOAuth2Details(oAuth2Details, errors, authenticationRules);
      break;
    case "ApiKey":
      validateApiKeyAuthDetails(apiKeyAuthDetails, errors, authenticationRules);
      break;
  }
};

const validateBasicAuthDetails = (
  basicAuthDetails: { userName?: string; password?: string } | undefined,
  errors: Record<string, string[]>,
  authenticationRules: AuthSettingsErrors
) => {
  if (!basicAuthDetails?.userName) {
    addError(errors, "userName", "Username is required.", authenticationRules);
  } else if (basicAuthDetails.userName.length > 50) {
    addError(
      errors,
      "userName",
      "Username must not exceed 50 characters.",
      authenticationRules
    );
  }

  if (!basicAuthDetails?.password) {
    addError(errors, "password", "Password is required.", authenticationRules);
  } else if (basicAuthDetails.password.length > 100) {
    addError(
      errors,
      "password",
      "Password must not exceed 100 characters.",
      authenticationRules
    );
  }
};

const validateOAuth2Details = (
  oAuth2Details: { clientId?: string } | undefined,
  errors: Record<string, string[]>,
  authenticationRules: AuthSettingsErrors
) => {
  if (!oAuth2Details?.clientId) {
    addError(errors, "clientId", "Client ID is required.", authenticationRules);
  } else if (oAuth2Details.clientId.length > 20) {
    addError(
      errors,
      "clientId",
      "Client ID must not exceed 20 characters.",
      authenticationRules
    );
  }
};

const validateApiKeyAuthDetails = (
  apiKeyAuthDetails: { apiKey?: string } | undefined,
  errors: Record<string, string[]>,
  authenticationRules: AuthSettingsErrors
) => {
  if (!apiKeyAuthDetails?.apiKey) {
    addError(errors, "apiKey", "API Key is required.", authenticationRules);
  } else if (apiKeyAuthDetails.apiKey.length > 100) {
    addError(
      errors,
      "apiKey",
      "API Key must not exceed 100 characters.",
      authenticationRules
    );
  }
};

const addError = (
  errors: Record<string, string[]>,
  field: string,
  message: string,
  authenticationRules: AuthSettingsErrors
) => {
  if (!errors[field]) {
    errors[field] = [];
  }
  errors[field].push(message);
  authenticationRules.authenticationRulesHasErrors = true;
};
