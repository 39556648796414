import { useDispatch, useSelector } from "react-redux";
import { Modal } from "@interstate/components/Modal";
import { PrimaryButton } from "@interstate/components/ActionButtons";
import {
  getAuthSettingsState,
  setModalContent
} from "../../slices/auth-settings";

export const AuthSettingsModal = () => {
  const dispatch = useDispatch();
  const { modalContext = {} } = useSelector(getAuthSettingsState);
  const {
    header = "Lead receiving auth settings",
    isModalOpen = false,
    content,
    isApiLoading = false,
    primary = {} as PrimaryButton
  } = modalContext;

  const onHide = () =>
    dispatch(setModalContent({ isModalOpen: false, primary, content }));

  return (
    <Modal
      footer={{
        options: [
          {
            action: onHide,
            label: "Cancel",
            disabled: isApiLoading,
            id: "oem-auth-settings-modal-cancel-button"
          }
        ],
        primary: {
          ...primary,
          isLoading: isApiLoading,
          id: "oem-auth-settings-modal-primary-button"
        }
      }}
      header={header}
      id="oem-auth-settings-modal"
      show={isModalOpen}
      size="large"
    >
      {content}
    </Modal>
  );
};
