import { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AuthSettingsButton } from "../auth-settings/authSettings.styles.component";
import { EnrichedAuthenticationRule } from "../../types/authSettings.type";
import { AuthSettingsAuthenticationRulesForm } from "./authSettingsAuthenticationRulesForm.component";
import { validateConductorAuthenticationRule } from "../../validators/conductorAuthenticationRules.validator";
import {
  clearErrorByKey,
  getAuthSettingsState,
  setAuthenticationRule,
  setConductorRuleSet,
  setErrors,
  setModalContent
} from "../../slices/auth-settings";
import {
  useAuthSettingsApp,
  useConductorRuleSet
} from "../../hooks/useAuthSettings.hook";

export const AuthSettingsAuthenticationRulesActionButton = ({
  initialAuthenticationRule,
  isEditMode = false,
  buttonIcon,
  buttonLabel
}: {
  initialAuthenticationRule: EnrichedAuthenticationRule;
  isEditMode?: boolean;
  buttonIcon: React.ReactNode;
  buttonLabel?: string;
}) => {
  const dispatch = useDispatch();
  const { putRuleSet } = useAuthSettingsApp();
  const { authenticationRule, isAddNewOem } = useSelector(getAuthSettingsState);
  const authenticationRuleRef = useRef(authenticationRule);
  const { conductorRuleSet } = useConductorRuleSet(
    initialAuthenticationRule.parentId
  );

  const updateAuthenticationRules = (
    authenticationRules: EnrichedAuthenticationRule[],
    currentauthenticationRule: EnrichedAuthenticationRule
  ): EnrichedAuthenticationRule[] => {
    const index = authenticationRules.findIndex(
      rule => rule.key === currentauthenticationRule.key
    );

    if (index > -1) {
      authenticationRules[index] = currentauthenticationRule;
    } else {
      authenticationRules.push(currentauthenticationRule);
    }

    return [...authenticationRules];
  };

  const handleSave = () => {
    if (!conductorRuleSet) return;

    const validationResult = validateConductorAuthenticationRule(
      authenticationRuleRef.current
    );

    if (validationResult.authenticationRulesHasErrors) {
      dispatch(setErrors(validationResult));

      return;
    }

    const updatedAuthenticatedRules = updateAuthenticationRules(
      [...conductorRuleSet.authenticationRules],
      authenticationRuleRef.current
    );
    const updatedConductorRuleSet = {
      ...conductorRuleSet,
      authenticationRules: updatedAuthenticatedRules
    };

    if (isAddNewOem) {
      dispatch(
        clearErrorByKey({
          fieldName: "conductorRuleSet",
          key: "authenticationRules"
        })
      );
      dispatch(setConductorRuleSet(updatedConductorRuleSet));
    } else {
      putRuleSet(updatedConductorRuleSet);
    }
  };

  const handleOnClick = () => {
    dispatch(setAuthenticationRule(initialAuthenticationRule));
    dispatch(
      setModalContent({
        header: isEditMode
          ? "Edit Authentication Rule"
          : "Add Authentication Rule",
        isModalOpen: true,
        primary: {
          action: handleSave,
          label: "Save"
        },
        content: <AuthSettingsAuthenticationRulesForm isEditMode={isEditMode} />
      })
    );
  };

  useEffect(() => {
    authenticationRuleRef.current = authenticationRule;
  }, [authenticationRule]);

  return (
    <AuthSettingsButton
      buttonStyle="secondary"
      id={`oem-auth-settings-action-button-authentication-rule-${
        isEditMode ? "edit" : "add"
      }-${conductorRuleSet?.oemSource}`}
      size="small"
      startIcon={buttonIcon}
      onClick={handleOnClick}
    >
      {buttonLabel}
    </AuthSettingsButton>
  );
};
