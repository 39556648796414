import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #f8d7da;
  color: #721c24;
  text-align: center;
  padding: 20px;
  border: 1px solid #f5c6cb;
  border-radius: 5px;
`;

export const Title = styled.h1`
  font-size: 2.5em;
  margin-bottom: 20px;
`;

export const Message = styled.p`
  font-size: 1.2em;
`;
