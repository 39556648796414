import { Table } from "@interstate/components/Table";
import { Alert } from "@interstate/components/Alert";
import { PlusIcon } from "@interstate/components/Icons";
import { generateId } from "../../helpers";
import { ERROR_MSG_ON_DELETE } from "../../constants";
import { EnrichedAuthenticationRule } from "../../types/authSettings.type";
import { AuthenticationRulesTableColumnsConfig } from "./authSettingsAuthenticationRules.config";
import { AuthSettingsAuthenticationRulesActionButton } from "./authSettingsAuthenticationRulesActionButton.component";
import {
  AuthenticationRuleDetails,
  AuthenticationRuleEnablement,
  AuthenticationType
} from "@vinsolutions/data-access/oem/coxauto.lead.oem.api";

export const AuthSettingsAuthenticationRules = ({
  authenticationRules,
  parentId
}: {
  authenticationRules: EnrichedAuthenticationRule[];
  parentId: string;
}) => {
  const defaultAuthenticationRule: EnrichedAuthenticationRule = {
    key: generateId(),
    parentId,
    enablement: {} as AuthenticationRuleEnablement,
    details: {} as AuthenticationRuleDetails,
    authenticationType: AuthenticationType.OAUTH2
  };

  return (
    <>
      <AuthSettingsAuthenticationRulesActionButton
        buttonIcon={<PlusIcon />}
        buttonLabel="Add Authentication Rule"
        initialAuthenticationRule={defaultAuthenticationRule}
      />
      <Table
        enablePagination
        columns={AuthenticationRulesTableColumnsConfig}
        data={authenticationRules}
        defaultPageSize={10}
        emptyText="Not authentication rules found."
        headerBackgroundColor="light"
        id="oem-auth-settings-authentication-rules-table"
        sortableDetails={{
          activeSortColumn: "authenticationType",
          sortOrder: "ascend",
          sortableColumns: ["authenticationType"]
        }}
      />
      {authenticationRules.length === 1 && (
        <Alert
          id="oem-auth-settings-authentication-rules-alert-message"
          type="caution"
        >
          {ERROR_MSG_ON_DELETE.replace(/\[NAME\]/g, "authentication rule")}
        </Alert>
      )}
    </>
  );
};
