import { Button } from "@interstate/components/Button";
import styled from "styled-components";

export const AuthSettingsContainer = styled.div`
  width: 100%;
  height: 100%;
  padding: 0.5rem;
`;

export const AuthSettingsButton = styled(Button)`
  margin-right: 0.2rem;
  margin-bottom: 0.2rem;
`;
