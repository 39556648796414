import { useReactOidc } from "@axa-fr/react-oidc-context";
import { LaunchDarklyVinconnect } from "@vinsolutions/core/third-party/launch-darkly";
import {
  DealSettingsResponse,
  DealSummary,
  getDealSettings
} from "@vinsolutions/data-access/workflow/crm.workflow.deal.api";
import { KeyInformationBannerModal } from "@vinsolutions/ui/key-information-banner";
import { useCallback, useEffect, useState } from "react";
import BuyingSignalsModelContent from "./buying-signals-modal-content";
import DealDetailsModalContent from "./deal-details-modal-content";
import { DealDetailsModal } from "./deal-details-modal/deal-details-modal";
import { AccelerateActivityModalContent } from "./accelerate-activity-modal-content";

export interface KeyInformationDealModalProps {
  customerId: number;
  leadId: number;
  dealerId: number;
  dealExchangeId: string | null;
  drActivityCount: number | null;
  showModal: boolean;
  onHide: () => void;
}

const KeyInformationDealModal = ({
  customerId,
  dealerId,
  leadId,
  dealExchangeId,
  drActivityCount,
  showModal,
  onHide
}: KeyInformationDealModalProps) => {
  // State
  const [dealSettings, setDealSettings] = useState<
    DealSettingsResponse | null | undefined
  >(null);
  const [selectedDealDetails, setSelectedDealDetails] =
    useState<DealSummary | null>(null);
  const jwt = useReactOidc().oidcUser.access_token;
  const dealModalEnabled = LaunchDarklyVinconnect.useVinconnectFlagEnabled(
    "nx.vinconnect.key-information.deal.modal"
  );

  const loadDealSettingsInfo = useCallback(async () => {
    const getDealSettingsResponse = dealModalEnabled
      ? await getDealSettings(leadId, dealerId, jwt)
      : null;

    setDealSettings(getDealSettingsResponse);
    if (
      getDealSettingsResponse &&
      getDealSettingsResponse.deals &&
      getDealSettingsResponse.deals.length > 0
    ) {
      setSelectedDealDetails(getDealSettingsResponse.deals[0]);
    }
  }, [dealModalEnabled, leadId, dealerId, jwt]);

  useEffect(() => {
    loadDealSettingsInfo();
  }, [loadDealSettingsInfo]);

  return (
    <KeyInformationBannerModal showModal={showModal} onHide={onHide}>
      {drActivityCount ? (
        dealModalEnabled && dealSettings ? (
          <AccelerateActivityModalContent
            customerId={customerId}
            dealExchangeId={dealExchangeId}
            dealSettingsResponse={dealSettings}
            dealSummary={selectedDealDetails}
            dealerId={dealerId}
            leadId={leadId}
          />
        ) : (
          <BuyingSignalsModelContent
            customerId={customerId}
            dealExchangeId={dealExchangeId}
            dealerId={dealerId}
            leadId={leadId}
          />
        )
      ) : dealModalEnabled && dealSettings ? (
        <DealDetailsModal
          dealSettings={dealSettings}
          dealSummary={selectedDealDetails}
          leadId={leadId}
        />
      ) : (
        <DealDetailsModalContent
          customerId={customerId}
          dealExchangeId={dealExchangeId}
          dealerId={dealerId}
          leadId={leadId}
        />
      )}
    </KeyInformationBannerModal>
  );
};

export default KeyInformationDealModal;
