import {
  Container,
  Message,
  Title
} from "./authSettingUnauthorized.styles.component";

export const AuthSettingsUnauthorized = () => {
  return (
    <Container>
      <Title id="oem-auth-settings-unauthorized-title">Unauthorized</Title>
      <Message id="oem-auth-settings-unauthorized-message">
        You are not authorized to see this view.
      </Message>
    </Container>
  );
};
