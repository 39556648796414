import { TableColumns } from "@interstate/components/Table";
import { AuthSettingsDeleteButton } from "./authSettingsDeleteButton.component";
import { EnrichedConductorRuleSet } from "../../types/authSettings.type";

export const AuthSettingsTableColumnsConfig: TableColumns = [
  {
    dataIndex: "oemSource",
    render: (_: unknown, record: EnrichedConductorRuleSet) => {
      return <label>{record.oemSource}</label>;
    }
  },
  {
    width: 110,
    fixed: "right",
    render: (_: unknown, record: EnrichedConductorRuleSet) => {
      return (
        <AuthSettingsDeleteButton
          authenticationRules={record.authenticationRules}
          id={record.id}
          key={record.key}
          oemSource={record.oemSource}
          permissions={record.permissions}
        />
      );
    }
  }
];
