import { CopyToDeskingEvent } from "./copy-to-desking-event";
import { LaunchUrlEvent } from "./launch-url-event";

export type DealApplicationEventType = "LaunchUrl" | "CopyToDesking";

export interface DealApplicationEventParams {
  applicationName: string;
  applicationUrl: string;
  dealExchangeDealId: string | null;
  dealExchangeVersionId: string | null;
  leadId?: number | undefined;
  onApplicationError?: (
    errorTitle: string | undefined,
    errorMessage: string | undefined
  ) => void;
}

const dealApplicationEvents: Record<
  DealApplicationEventType,
  (params: DealApplicationEventParams) => Promise<void>
> = {
  LaunchUrl: LaunchUrlEvent,
  CopyToDesking: CopyToDeskingEvent
};

export const getDealApplicationEvent = (eventType: string) => {
  if (eventType in dealApplicationEvents) {
    return dealApplicationEvents[
      eventType as keyof typeof dealApplicationEvents
    ];
  }

  return undefined;
};
