import { getBaseUrl } from "@vinsolutions/core/environment";
import { DeskingApiConfig } from "./desking.api.config";
import { fetchWrapper } from "@vinsolutions/core-http";
import { CreateDeskFromDXGResponse } from "./models/create-desk-from-dxg-response.model";

export async function createDeskFromDxg(
  dealExchangeDealId: string | null,
  dealExchangeVersionId: string | null
): Promise<{
  deskCreated: boolean;
  hasError: boolean;
  errorMessage: string | undefined;
}> {
  if (dealExchangeDealId === null) {
    return {
      deskCreated: false,
      hasError: true,
      errorMessage: "DealExchangeDealId is not provided"
    };
  }
  const createDeskFromDxgUrl = getCreateDeskFromDxgUrl(
    dealExchangeDealId,
    dealExchangeVersionId
  );
  let deskCreated = false;
  let hasError = false;
  let errorMessage: string | undefined = "";

  const response = await fetchWrapper.postAsync<CreateDeskFromDXGResponse>(
    createDeskFromDxgUrl,
    null,
    {
      Accept: "application/json",
      "Content-Type": "application/json"
    }
  );

  if (response && response.statusCode === 200) {
    deskCreated = true;
  } else if (response && response.hasError) {
    hasError = true;
    errorMessage = response?.data?.message;
  }
  return { deskCreated, hasError, errorMessage };
}

export function getCreateDeskFromDxgUrl(
  dealExchangeDealId: string | null,
  dealExchangeVersionId: string | null
): string {
  const baseUrl = getBaseUrl(DeskingApiConfig.baseUrls);
  return `${baseUrl}desking.api/DeskingIntegration/CreateDeskFromDXG?dxgId=${dealExchangeDealId}&dxgVersion=${dealExchangeVersionId}`;
}
