import { subDays } from "date-fns";
import { Response, SummaryResponse } from "..";
import { fakeSalesFunnelCounts } from "./fakeSalesFunnelCounts";

export const fakeSummaryResponse: Response<SummaryResponse> = {
  startDateUtc: subDays(new Date(), 14),
  endDateUtc: new Date(),
  result: {
    buyingSignalsCount: 0,
    hasMailLetters: true,
    pendingSalesCount: 10,
    openShowroomVisitsCount: 5,
    responseTimes: [
      { label: "noContact", value: 3, type: "No Contact" },
      { label: "0.25", value: 1, type: "Target 1" },
      { label: "0.50", value: 1, type: "Target 2" },
      { label: "missed", value: 0, type: "Missed" }
    ],
    unAnsweredCommunicationCount: 100,
    salesFunnelCounts: fakeSalesFunnelCounts
  }
};
