import { useDispatch, useSelector } from "react-redux";
import { TextInput } from "@interstate/components/TextInput";
import { InterstateOnChangeEvent } from "@interstate/components/InterstateEvents";
import {
  clearErrorByKey,
  getAuthSettingsState,
  setAuthenticationRule
} from "../../slices/auth-settings";

export const AuthSettingsAuthenticationRulesApiKey = () => {
  const dispatch = useDispatch();
  const { authenticationRule } = useSelector(getAuthSettingsState);

  const handleBlur = (event: InterstateOnChangeEvent<string>) => {
    const value = event.target.value;
    const updatedAuthenticationRule = {
      ...authenticationRule,
      apiKeyAuthDetails: { apiKey: value }
    };

    dispatch(setAuthenticationRule(updatedAuthenticationRule));

    if (
      value !== "" &&
      authenticationRule.apiKeyAuthDetails?.apiKey !== value
    ) {
      dispatch(
        clearErrorByKey({ fieldName: "authenticationRules", key: "apiKey" })
      );
    }
  };

  return (
    <TextInput
      id="oem-auth-settings-authentication-rules-input-apikey"
      label="API Key"
      name="apiKeyAuthDetails.apiKey"
      value={authenticationRule.apiKeyAuthDetails?.apiKey}
      onBlur={handleBlur}
    />
  );
};
