import styled from "styled-components";
import { ApplicationSelectorProps } from "../../interfaces/deal-details-modal/application-selector-props";
import ApplicationButton from "./application-button";

const StyledApplicationSelector = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 16px;
`;

const ApplicationSelector = ({ applications }: ApplicationSelectorProps) => {
  return (
    <StyledApplicationSelector>
      {applications.map(application => {
        return (
          <ApplicationButton
            htmlId={application.htmlId}
            key={application.htmlId}
            label={application.label}
            name={application.name}
            onClick={application.onClick}
          />
        );
      })}
    </StyledApplicationSelector>
  );
};

export default ApplicationSelector;
