import { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { EnrichedPermission } from "../../types/authSettings.type";
import { AuthSettingsButton } from "../auth-settings/authSettings.styles.component";
import { validateConductorPermission } from "../../validators/conductorPermission.validator";
import { AuthSettingsPermissionsForm } from "./authSettingsPermissionsForm.component";
import {
  useAuthSettingsApp,
  useConductorRuleSet
} from "../../hooks/useAuthSettings.hook";
import {
  clearErrorByKey,
  getAuthSettingsState,
  setConductorRuleSet,
  setErrors,
  setModalContent,
  setPermission
} from "../../slices/auth-settings";

export const AuthSettingsPermissionsActionButton = ({
  initialPermission,
  isEditMode = false,
  buttonIcon,
  buttonLabel
}: {
  initialPermission: EnrichedPermission;
  isEditMode?: boolean;
  buttonIcon: React.ReactNode;
  buttonLabel?: string;
}) => {
  const dispatch = useDispatch();
  const { putRuleSet } = useAuthSettingsApp();
  const { isAddNewOem, permission } = useSelector(getAuthSettingsState);
  const permissionRef = useRef(permission);
  const { conductorRuleSet } = useConductorRuleSet(initialPermission.parentId);

  const handleSave = () => {
    if (!conductorRuleSet) return;

    const validationResult = validateConductorPermission(
      { ...conductorRuleSet },
      permissionRef.current
    );

    if (validationResult.permissionsHasErrors) {
      dispatch(setErrors(validationResult));

      return;
    }

    const updatedPermissions = updatePermissions(
      [...conductorRuleSet.permissions],
      permissionRef.current
    );

    const updatedConductorRuleSet = {
      ...conductorRuleSet,
      permissions: updatedPermissions
    };

    if (isAddNewOem) {
      dispatch(
        clearErrorByKey({ fieldName: "conductorRuleSet", key: "permissions" })
      );
      dispatch(setConductorRuleSet(updatedConductorRuleSet));
    } else {
      putRuleSet(updatedConductorRuleSet);
    }
  };

  const updatePermissions = (
    permissions: EnrichedPermission[],
    currentPermission: EnrichedPermission
  ): EnrichedPermission[] => {
    const index = permissions.findIndex(
      perm => perm.key === currentPermission.key
    );

    if (index > -1) {
      permissions[index] = currentPermission;
    } else {
      permissions.push(currentPermission);
    }

    return [...permissions];
  };

  const handleOnClick = () => {
    dispatch(setPermission(initialPermission));
    dispatch(
      setModalContent({
        header: isEditMode ? "Edit Permission" : "Add Permission",
        isModalOpen: true,
        primary: {
          action: handleSave,
          label: "Save"
        },
        content: <AuthSettingsPermissionsForm isEditMode={isEditMode} />
      })
    );
  };

  useEffect(() => {
    permissionRef.current = permission;
  }, [permission]);

  return (
    <AuthSettingsButton
      buttonStyle="secondary"
      id={`oem-auth-settings-action-button-permission-${
        isEditMode ? "edit" : "add"
      }-${conductorRuleSet?.oemSource}`}
      size="small"
      startIcon={buttonIcon}
      onClick={handleOnClick}
    >
      {buttonLabel}
    </AuthSettingsButton>
  );
};
