import { useDispatch, useSelector } from "react-redux";
import { TextArea } from "@interstate/components/TextArea";
import { TextInput } from "@interstate/components/TextInput";
import {
  clearErrorByKey,
  getAuthSettingsState,
  setAuthenticationRule
} from "../../slices/auth-settings";

export const AuthSettingsAuthenticationRulesDetails = () => {
  const dispatch = useDispatch();
  const { authenticationRule } = useSelector(getAuthSettingsState);

  const handleBlur = (key: "title" | "description", value: string) => {
    const updatedAuthenticationRule = {
      ...authenticationRule,
      details: {
        ...authenticationRule.details,
        [key]: value
      }
    };

    dispatch(setAuthenticationRule(updatedAuthenticationRule));

    if (
      value !== "" &&
      (authenticationRule.details.title !== value ||
        authenticationRule.details.description !== value)
    ) {
      dispatch(clearErrorByKey({ fieldName: "authenticationRules", key }));
    }
  };

  return (
    <>
      <TextInput
        id="oem-auth-settings-authentication-rules-input-title"
        label="Title"
        name="details.title"
        value={authenticationRule.details.title}
        onBlur={event => handleBlur("title", event.target.value)}
      />
      <TextArea
        id="oem-auth-settings-authentication-rules-input-description"
        label="Description"
        name="details.description"
        rows={4}
        value={authenticationRule.details.description}
        onBlur={event => handleBlur("description", event.target.value)}
      />
    </>
  );
};
