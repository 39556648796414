import { subDays } from "date-fns";
import { DetailResponse } from "..";
import { ReportType } from "../../enums";
import LeadType from "../../enums/leadType";
import SoldStatus from "../../enums/soldStatus";
import AssignedUserType from "../../enums/assignedUserType";
import AppointmentStatus from "../../enums/appointmentStatus";

export const fakeDetailResponse: DetailResponse = {
  startDateUtc: subDays(new Date(), 14),
  endDateUtc: new Date(),
  reportType: ReportType.RESPONSE_TIME_MISSED,
  result: [
    {
      activityDateTimeUtc: "2023-12-06T16:02:00",
      lead: {
        id: 1512409886,
        leadType: LeadType.PHONE,
        leadStatusName: SoldStatus.ON_ORDER,
        leadStatusTypeName: "Sold",
        leadSourceId: 16233,
        leadSourceName: "Autoalert",
        leadAgeInDays: 23
      },
      customer: {
        id: 1110465143,
        firstName: "Coco",
        lastName: "Melon",
        salesRepresentative: {
          id: 545993,
          firstName: "Andrea",
          lastName: "Jones",
          assignedUserType: AssignedUserType.SALES_REPRESENTATIVE
        }
      },
      leadVehicle: {
        stockNumber: "20223373",
        year: 2022,
        make: "Honda",
        model: "Pilot"
      },
      tradeVehicle: {
        year: 2022,
        make: "Honda",
        model: "Pilot"
      },
      responseTimeActual: 0.5,
      responseTimeAdjusted: 0.7,
      responseTimeTarget: "Missed",
      visitDurationInMinutes: 100,
      soldDateUtc: "2023-12-06T16:02:00",
      customerFirstContactedUtc: "2023-12-06T16:02:00",
      appointmentUtc: "2023-12-06T16:02:00",
      visitStartTimeUtc: "2023-12-06T16:02:00",
      appointmentStatus: AppointmentStatus.SET,
      unansweredCommunication: {
        taskDueDateUtc: "2023-12-06T16:02:00",
        taskAgeInDays: 3,
        type: "EMAIL",
        userGroupName: "group name",
        assignedUserName: "assigned user name"
      }
    }
  ]
};
