import {
  AuthSettingsErrors,
  EnrichedConductorRuleSet
} from "../types/authSettings.type";

export const validateConductorRuleSet = (
  conductorRuleSet: EnrichedConductorRuleSet,
  conductorRuleSets: EnrichedConductorRuleSet[]
): AuthSettingsErrors => {
  const errors: Record<string, string[]> = {};
  const conductorRuleErrors: AuthSettingsErrors = { conductorRuleSet: errors };

  const addError = (field: keyof EnrichedConductorRuleSet, message: string) => {
    conductorRuleErrors.conductorRuleSetHasErrors = true;

    if (!errors[field]) {
      errors[field] = [];
    }

    errors[field].push(message);
  };

  if (!conductorRuleSet.oemSource) {
    addError("oemSource", "OEM is required.");
  } else {
    const ruleSetExists = conductorRuleSets.some(
      ruleSet => ruleSet.oemSource === conductorRuleSet.oemSource
    );

    if (ruleSetExists) {
      addError(
        "oemSource",
        "The specified OEM source already exists. Please choose a different OEM source."
      );
    }
  }

  if (conductorRuleSet.authenticationRules.length === 0) {
    addError(
      "authenticationRules",
      "At least one authentication rule is required."
    );
  }

  if (conductorRuleSet.permissions.length === 0) {
    addError("permissions", "At least one permission is required.");
  }

  return conductorRuleErrors;
};
