import { useDispatch, useSelector } from "react-redux";
import { Grid } from "@interstate/components/Grid";
import { TextInput } from "@interstate/components/TextInput";
import {
  clearErrorByKey,
  getAuthSettingsState,
  setAuthenticationRule
} from "../../slices/auth-settings";

export const AuthSettingsAuthenticationRulesBasic = () => {
  const dispatch = useDispatch();
  const { authenticationRule } = useSelector(getAuthSettingsState);

  const handleBlur = (key: "userName" | "password", value: string) => {
    const updatedAuthenticationRule = {
      ...authenticationRule,
      basicAuthDetails: {
        ...authenticationRule.basicAuthDetails,
        [key]: value
      }
    };

    dispatch(setAuthenticationRule(updatedAuthenticationRule));

    if (value !== "" && authenticationRule.basicAuthDetails?.[key] !== value) {
      dispatch(clearErrorByKey({ fieldName: "authenticationRules", key }));
    }
  };

  return (
    <Grid container columnSpacing={1} rowSpacing={1}>
      <Grid xs={6}>
        <TextInput
          id="oem-auth-settings-authentication-rules-input-username"
          label="Username"
          name="basicAuthDetails.userName"
          value={authenticationRule.basicAuthDetails?.userName}
          onBlur={event => handleBlur("userName", event.target.value)}
        />
      </Grid>
      <Grid xs={6}>
        <TextInput
          id="oem-auth-settings-authentication-rules-input-password"
          label="Password"
          name="basicAuthDetails.password"
          type="password"
          value={authenticationRule.basicAuthDetails?.password}
          onBlur={event => handleBlur("password", event.target.value)}
        />
      </Grid>
    </Grid>
  );
};
