import { lazy } from "react";

export const AcquisitionLeadAddPage = lazy(() => {
  return import("./lib/acquisition-lead-add").then(module => ({
    default: module.AcquisitionLeadAdd
  }));
});

export * from "./lib/acquisition-lead-add";
export * from "./lib/interfaces/acquisition-lead-add-props";
