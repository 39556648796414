import { useDispatch, useSelector } from "react-redux";
import { AuthenticationType } from "@vinsolutions/data-access/oem/coxauto.lead.oem.api";
import { InterstateOnChangeEvent } from "@interstate/components/InterstateEvents";
import { AuthSettingsAuthenticationRulesBasic } from "./authSettingsAuthenticationRulesBasic.component";
import { AuthSettingsAuthenticationRulesOAuth } from "./authSettingsAuthenticationRulesOAuth.component";
import { AuthSettingsAuthenticationRulesApiKey } from "./authSettingsAuthenticationRulesApiKey.component";
import {
  SelectInput,
  SelectInputEventValue
} from "@interstate/components/SelectInput";
import {
  clearErrorByKey,
  getAuthSettingsState,
  setAuthenticationRule
} from "../../slices/auth-settings";

export const AuthSettingsAuthenticationRulesAuthType = ({
  disabled
}: {
  disabled: boolean;
}) => {
  const dispatch = useDispatch();
  const { authenticationRule } = useSelector(getAuthSettingsState);
  const authType = authenticationRule.authenticationType;

  const handleAuthTypeChange = (
    event: InterstateOnChangeEvent<SelectInputEventValue>
  ) => {
    const value = event.target.value as AuthenticationType;

    if (value !== authenticationRule.authenticationType) {
      dispatch(clearErrorByKey({ fieldName: "authenticationRules", key: "*" }));
      dispatch(
        setAuthenticationRule({
          ...authenticationRule,
          authenticationType: value
        })
      );
    }
  };

  return (
    <>
      <SelectInput
        disabled={disabled}
        displayDeselectOption={false}
        id="oem-auth-settings-authentication-rules-select"
        label="Type"
        name="authenticationType"
        options={[
          {
            label: "Basic",
            value: "Basic"
          },
          {
            label: "OAuth2",
            value: "OAuth2"
          },
          {
            label: "API Key",
            value: "ApiKey"
          }
        ]}
        value={authType.toString()}
        onChange={handleAuthTypeChange}
      />
      {authType === "Basic" && <AuthSettingsAuthenticationRulesBasic />}
      {authType === "ApiKey" && <AuthSettingsAuthenticationRulesApiKey />}
      {authType === "OAuth2" && <AuthSettingsAuthenticationRulesOAuth />}
    </>
  );
};
