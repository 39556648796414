import { useEffect, useState } from "react";
import styled from "styled-components";
import { CARDASHBOARD_RIMS_BASEPATH } from "../constants/rims-frame-url";

export interface DealDetailsModalContentProps {
  customerId: number;
  leadId: number;
  dealerId: number;
  dealExchangeId: string | null;
}

const DIGITAL_DEAL_SETTING_NAME = "CADR.sdk.url";

export const getDealDetailsIframeUrl = (
  customerId: number,
  leadId: number,
  dealerId: number,
  dealExchangeId: string | null
) => {
  return dealExchangeId
    ? `${CARDASHBOARD_RIMS_BASEPATH}?AMDCMD=True&display=dealdetails&dealXGId=${dealExchangeId}&urlSettingName=${DIGITAL_DEAL_SETTING_NAME}&customerId=${customerId}&autoLeadId=${leadId}&accelerateLeadId=${leadId}&dealerId=${dealerId}&HideCloseButton=true`
    : "";
};

const StyledDealDetailsModalContent = styled.iframe`
  width: 100%;
  height: 900px;
  border: none;
`;

const DealDetailsModalContent = ({
  customerId,
  dealerId,
  leadId,
  dealExchangeId
}: DealDetailsModalContentProps) => {
  const [iframeUrl, setIFrameUrl] = useState("");

  useEffect(() => {
    const dealDetailsUrl = getDealDetailsIframeUrl(
      customerId,
      leadId,
      dealerId,
      dealExchangeId
    );
    setIFrameUrl(dealDetailsUrl);
  }, [customerId, dealerId, leadId, dealExchangeId]);

  return (
    iframeUrl && (
      <StyledDealDetailsModalContent src={iframeUrl} title="Accelerate-Modal" />
    )
  );
};

export default DealDetailsModalContent;
