import { useSelector } from "react-redux";
import { getAuthSettingsState } from "../../slices/auth-settings";
import { AuthSettingsAlertErrors } from "../auth-settings-alert-errors.component.tsx/authSettingsAlertErrors.component";
import { AuthSettingsAuthenticationRulesDetails } from "./authSettingsAuthenticationRulesDetails.component";
import { AuthSettingsAuthenticationRulesAuthType } from "./authSettingsAuthenticationRulesAuthType.component";
import { AuthSettingsAuthenticationRulesEnablement } from "./authSettingsAuthenticationRulesEnablement.component";

export const AuthSettingsAuthenticationRulesForm = ({
  isEditMode = false
}: {
  isEditMode?: boolean;
}) => {
  const { validationErrors } = useSelector(getAuthSettingsState);

  return (
    <>
      {validationErrors.authenticationRules &&
        validationErrors.authenticationRulesHasErrors && (
          <AuthSettingsAlertErrors
            errors={validationErrors.authenticationRules}
          />
        )}
      <AuthSettingsAuthenticationRulesDetails />
      <AuthSettingsAuthenticationRulesAuthType disabled={isEditMode} />
      <AuthSettingsAuthenticationRulesEnablement />
    </>
  );
};
