import { PropDefinition } from "../interfaces/prop-definition";
import { createLogger } from "@vinsolutions/logger";

const logger = createLogger("feature-overlays-validate-props");

/**
 * Validates the props against the required prop definitions.
 *
 * @param {any} props - The props to validate.
 * @param {PropDefinition[]} requiredProps - The required prop definitions.
 * @returns {boolean} True if the props are valid, false otherwise.
 */
export const validateProps = (
  props: any,
  requiredProps: PropDefinition[]
): boolean => {
  if (typeof props !== "object" || props === null) {
    logger.error("Props should be an object");

    return false;
  }

  for (const field of requiredProps) {
    const { name, type } = field;

    if (!(name in props)) {
      logger.error(`Missing required property: ${name}`);

      return false;
    }

    if (typeof props[name] !== type) {
      logger.error(`Invalid type for ${name}: expected ${type}`);

      return false;
    }
  }

  return true;
};
