import { Button } from "@interstate/components/Button";
import { Box } from "@interstate/components/Box";
import { AddLeadCtasProps } from "../interfaces/lead-ctas-props";
import {
  createLead,
  getLeadCreationStatus
} from "@vinsolutions/data-access/lead/crm.lead.bff";
import { useCallback } from "react";
import { TagManager } from "@vinsolutions/core-third-party-gtm";
import { useNavigate } from "react-router-dom";

export function AddLeadCtas({
  customerId,
  dealerId,
  jwt,
  leadInformation,
  vehicleInformation,
  setIsLoading
}: AddLeadCtasProps) {
  const navigate = useNavigate();

  const callCreateLead = useCallback(async () => {
    if (
      customerId > 0 &&
      dealerId > 0 &&
      jwt &&
      leadInformation &&
      leadInformation.intent &&
      leadInformation.intent > 0 &&
      leadInformation.source &&
      leadInformation.source > 0 &&
      leadInformation.type &&
      leadInformation.type > 0 &&
      vehicleInformation
    ) {
      // TODO: Update GTM event once requirements are known (or replace with PixAll?)
      TagManager.event({
        event: "userEvent",
        eventElement: "AddLead",
        eventAction: "Save button clicked",
        eventResult: "Saved lead",
        eventLocation: "AddLead"
      });

      const token = await createLead(jwt, leadInformation, true);

      if (token) {
        setIsLoading(true);

        const pollLeadStatus = async () => {
          try {
            const leadId = await getLeadCreationStatus(jwt, token, true);

            if (leadId && leadId > 0) {
              setIsLoading(false);

              navigate(
                `/vinconnect/#/CarDashboard/Pages/LeadManagement/ActiveLeadsLayout.aspx?SelectedTab=t_ILM&leftpaneframe=HIDE&rightpaneframe=/CarDashboard/Pages/CRM/CustomerDashboard.aspx?AutoLeadID=${leadId}&r=${Math.random()}`
              );
            }
          } catch (error) {
            // ignore
          }
        };

        const intervalId = setInterval(pollLeadStatus, 1000);

        setTimeout(() => {
          clearInterval(intervalId);
          setIsLoading(false);

          // TODO: If we didn't get leadId back in the 5 seconds, then display some message. For now just navigating to the customers dashboard
          navigate(
            `/vinconnect/#/CarDashboard/Pages/LeadManagement/ActiveLeadsLayout.aspx?SelectedTab=t_ILM&leftpaneframe=HIDE&rightpaneframe=/CarDashboard/Pages/CRM/CustomerDashboard.aspx?GlobalCustomerID=${customerId}&r=${Math.random()}`
          );
        }, 5000);
      } else {
        // TODO: Show error message
      }
    }
  }, [customerId, dealerId, jwt, leadInformation, vehicleInformation]);

  return (
    <Box
      columnGap="1.5em"
      display={
        customerId > 0 &&
        leadInformation &&
        leadInformation.intent &&
        leadInformation.intent > 0 &&
        leadInformation.source &&
        leadInformation.source > 0 &&
        leadInformation.type &&
        leadInformation.type > 0
          ? "flex"
          : "none"
      }
      justifyContent={"flex-end"}
      paddingTop={"1em"}
    >
      <Button
        buttonStyle="primary"
        data-testid="button-create"
        id="create-button"
        size="medium"
        onClick={() => callCreateLead()}
      >
        Add Lead
      </Button>
    </Box>
  );
}

export default AddLeadCtas;
