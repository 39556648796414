import { AuthSettingsPermissions } from "../auth-settings-permissions/authSettingsPermissions.component";
import { AuthSettingsAuthenticationRules } from "../auth-settings-authentication-rules/authSettingsAuthenticationRules.component";
import { EnrichedConductorRuleSet } from "../../types/authSettings.type";
import {
  Accordion,
  AccordionDetails,
  AccordionGroup,
  AccordionSummary
} from "@interstate/components/AccordionGroup";

export const AuthSettingsAccordionGroup = (
  conductorRuleSet: EnrichedConductorRuleSet
) => {
  return (
    <AccordionGroup accordionType="multi-expanded">
      <Accordion>
        <AccordionSummary id="oem-auth-settings-accordion-summary-authentication-rules">
          <span>Authentication Rules</span>
        </AccordionSummary>
        <AccordionDetails>
          <AuthSettingsAuthenticationRules
            authenticationRules={conductorRuleSet.authenticationRules}
            parentId={conductorRuleSet.key}
          />
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary id="oem-auth-settings-accordion-summary-permissions">
          <span>Permissions</span>
        </AccordionSummary>
        <AccordionDetails>
          <AuthSettingsPermissions
            parentId={conductorRuleSet.key}
            permissions={conductorRuleSet.permissions}
          />
        </AccordionDetails>
      </Accordion>
    </AccordionGroup>
  );
};
