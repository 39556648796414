import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Badge } from "@interstate/components/Badge";
import { useReactOidc } from "@axa-fr/react-oidc-context";
import { AuthSettingsComboBox } from "../../types/authSettings.type";
import { InformationCircleIcon } from "@interstate/components/Icons";
import { InterstateOnChangeEvent } from "@interstate/components/InterstateEvents";
import {
  clearErrorByKey,
  getAuthSettingsState,
  setPermission
} from "../../slices/auth-settings";
import {
  SelectInput,
  SelectInputEventValue
} from "@interstate/components/SelectInput";
import {
  Permission,
  coxAutoLeadOemApiClient as apiClient
} from "@vinsolutions/data-access/oem/coxauto.lead.oem.api";

const fetchPermissions = async (
  jwt: string
): Promise<AuthSettingsComboBox[]> => {
  try {
    const permissionCollection: Permission[] =
      await apiClient.getAllConductorAdminPermissionsAsync(jwt);

    return permissionCollection.map(permission => ({
      label: permission.name,
      value: permission.name,
      description: permission.description || ""
    }));
  } catch (error) {
    return [];
  }
};

export const AuthSettingsPermissionsSelect = ({
  disabled = false
}: {
  disabled?: boolean;
}) => {
  const dispatch = useDispatch();
  const { permission } = useSelector(getAuthSettingsState);
  const [options, setOptions] = useState<AuthSettingsComboBox[]>([]);
  const {
    oidcUser: { access_token: jwt }
  } = useReactOidc();

  const selectedDescription = options.find(
    option => option.value === permission.action
  )?.description;

  const onChange = (event: InterstateOnChangeEvent<SelectInputEventValue>) => {
    const value = event.target.value?.toString() || "";
    const updatedPermission = { ...permission, action: value };

    dispatch(setPermission(updatedPermission));

    if (permission.action !== value) {
      dispatch(clearErrorByKey({ fieldName: "permissions", key: "action" }));
    }
  };

  useEffect(() => {
    if (jwt) {
      fetchPermissions(jwt).then(setOptions);
    }
  }, [jwt]);

  return (
    <>
      <SelectInput
        required
        disabled={disabled}
        displayDeselectOption={false}
        id="oem-auth-settings-permissions-select"
        label="Action"
        options={options}
        size="md"
        value={permission.action}
        onChange={onChange}
      />
      {permission.action && selectedDescription && (
        <Badge
          startIcon={<InformationCircleIcon />}
          sx={{ borderRadius: "5px" }}
          variant="info"
        >
          {selectedDescription}
        </Badge>
      )}
    </>
  );
};
