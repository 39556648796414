import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal } from "@interstate/components/Modal";
import { PlusIcon } from "@interstate/components/Icons";
import { generateId } from "../../helpers";
import { AuthSettingsForm } from "./authSettingsForm.component";
import { AuthSettingsButton } from "./authSettings.styles.component";
import { useAuthSettingsApp } from "../../hooks/useAuthSettings.hook";
import { validateConductorRuleSet } from "../../validators/conductorRuleSet.validator";
import {
  clearErrorByKey,
  getAuthSettingsState,
  setAddNewOem,
  setConductorRuleSet,
  setErrors
} from "../../slices/auth-settings";

export const AuthSettingsAddButton = () => {
  const dispatch = useDispatch();
  const { postRuleSet } = useAuthSettingsApp();
  const [showModal, setShowModal] = useState(false);
  const { conductorRuleSets, conductorRuleSet, isAuthSettingsLoading } =
    useSelector(getAuthSettingsState);

  return (
    <>
      <Modal
        footer={{
          options: [
            {
              action: () => {
                setShowModal(false);
                dispatch(setAddNewOem(false));
                dispatch(
                  clearErrorByKey({ fieldName: "conductorRuleSet", key: "*" })
                );
              },
              label: "Cancel",
              disabled: isAuthSettingsLoading,
              id: "oem-auth-settings-add-oem-modal-cancel-button"
            }
          ],
          primary: {
            action: () => {
              const validationResult = validateConductorRuleSet(
                conductorRuleSet,
                conductorRuleSets
              );

              if (validationResult.conductorRuleSetHasErrors) {
                dispatch(setErrors(validationResult));

                return;
              }

              postRuleSet(conductorRuleSet);
              setShowModal(false);
            },
            label: "Save",
            disabled: isAuthSettingsLoading,
            id: "oem-auth-settings-add-oem-modal-primary-button"
          }
        }}
        header="Add OEM"
        id="oem-auth-settings-add-oem-modal"
        show={showModal}
        size="fullScreen"
      >
        <AuthSettingsForm />
      </Modal>
      <AuthSettingsButton
        buttonStyle="secondary"
        disabled={isAuthSettingsLoading}
        id="oem-auth-settings-add-oem"
        size="sm"
        startIcon={<PlusIcon />}
        onClick={() => {
          const key = generateId();

          dispatch(setAddNewOem(true));
          dispatch(
            setConductorRuleSet({
              key,
              id: key,
              oemSource: "",
              authenticationRules: [],
              permissions: []
            })
          );
          setShowModal(true);
        }}
      >
        Add new OEM
      </AuthSettingsButton>
    </>
  );
};
