import { KeyPerformanceIndicatorsDto } from "../../types";
import { fakeSummaryResponse } from "./fakeSummaryResponse";

export const fakeKeyPerformanceIndicatorsDto: KeyPerformanceIndicatorsDto = {
  buyingSignalsCount: fakeSummaryResponse.result.buyingSignalsCount,
  unAnsweredCommunicationCount:
    fakeSummaryResponse.result.unAnsweredCommunicationCount,
  pendingSalesCount: fakeSummaryResponse.result.pendingSalesCount,
  openShowroomVisitsCount: fakeSummaryResponse.result.openShowroomVisitsCount
};
