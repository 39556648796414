import { createElement } from "react";
import { registerOverlay } from "./vinconnect-overlay-register";
// eslint-disable-next-line @nx/enforce-module-boundaries
import {
  AcquisitionLeadAdd,
  AcquisitionLeadAddProps
} from "@vinsolutions/page/acquisition-lead-add";

registerOverlay<AcquisitionLeadAddProps>({
  name: "AcquisitionLeadAdd",
  component: props => createElement(AcquisitionLeadAdd, props),
  requiredProps: [{ name: "customerId", type: "number" }]
});
