import { useDispatch, useSelector } from "react-redux";
import { TrashIcon } from "@interstate/components/Icons";
import {
  useAuthSettingsApp,
  useConductorRuleSet
} from "../../hooks/useAuthSettings.hook";
import { EnrichedAuthenticationRule } from "../../types/authSettings.type";
import {
  getAuthSettingsState,
  setConductorRuleSet,
  setModalContent
} from "../../slices/auth-settings";
import { AuthSettingsButton } from "../auth-settings/authSettings.styles.component";

export const AuthSettingsAuthenticationRulesDeleteButton = ({
  rule
}: {
  rule: EnrichedAuthenticationRule;
}) => {
  const dispatch = useDispatch();
  const { putRuleSet } = useAuthSettingsApp();
  const { isAddNewOem } = useSelector(getAuthSettingsState);
  const { conductorRuleSet } = useConductorRuleSet(rule.parentId);

  const removeAuthenticationRule = () => {
    if (conductorRuleSet) {
      const updatedAuthenticationRules =
        conductorRuleSet.authenticationRules.filter(
          authenticationRule => authenticationRule.key !== rule.key
        );

      const updatedRuleSet = {
        ...conductorRuleSet,
        authenticationRules: updatedAuthenticationRules
      };

      if (!updatedRuleSet) return;

      if (isAddNewOem) {
        dispatch(setConductorRuleSet(updatedRuleSet));
      } else {
        putRuleSet(updatedRuleSet);
      }
    }
  };

  const handleOnClick = () => {
    dispatch(
      setModalContent({
        header: "Delete authentication rule",
        isModalOpen: true,
        primary: {
          action: removeAuthenticationRule,
          label: "Delete"
        },
        content: (
          <label>
            Are you sure you want to remove <b>{rule.details.title}</b>?
          </label>
        )
      })
    );
  };

  return (
    <AuthSettingsButton
      buttonStyle="secondary"
      disabled={conductorRuleSet?.authenticationRules.length === 1}
      icon={<TrashIcon />}
      id="oem-auth-settings-action-delete-authentication-rule"
      size="small"
      onClick={handleOnClick}
    />
  );
};
