import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useToaster } from "@interstate/components/Toast";
import { AuthSettings } from "./components/auth-settings/authSettings.component";
import { AuthSettingsModal } from "./components/auth-settings-modal/authSettingsModal.component";
import { getAuthSettingsState } from "./slices/auth-settings";
import { AuthSettingsContainer } from "./components/auth-settings/authSettings.styles.component";
import { CheckmarkSolidIcon, XMarkIcon } from "@interstate/components/Icons";

export const LeadReceivingAuthSettings = () => {
  const { toast } = useToaster();
  const { toastContext } = useSelector(getAuthSettingsState);

  useEffect(() => {
    if (toastContext.message) {
      const toastTitle = toastContext.isSuccessful
        ? "Operation Completed"
        : "Operation Failed";

      toast(
        toastTitle,
        toastContext.message,
        () => {
          return;
        },
        toastContext.isSuccessful ? <CheckmarkSolidIcon /> : <XMarkIcon />
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toastContext]);

  return (
    <AuthSettingsContainer>
      <AuthSettingsModal />
      <AuthSettings />
    </AuthSettingsContainer>
  );
};
