import { createAsyncThunk } from "@reduxjs/toolkit";
import { coxAutoLeadOemApiClient as apiClient } from "@vinsolutions/data-access/oem/coxauto.lead.oem.api";
import { EnrichedConductorRuleSet } from "../../types/authSettings.type";

export const fetchAllRuleSets = createAsyncThunk(
  "conductorRuleSet/getAll",
  async ({ jwt }: { jwt: string }) =>
    await apiClient.getAllConductorAdminRuleSetsAsync(jwt)
);

export const deleteRuleSetByOem = createAsyncThunk(
  "conductorRuleSet/delete",
  async ({ oem, jwt }: { oem: string; jwt: string }) => {
    await apiClient.deleteConductorAdminRuleSetByOemAsync(oem, jwt);

    return oem;
  }
);

export const updateRuleSet = createAsyncThunk(
  "conductorRuleSet/put",
  async ({
    conductorRuleSet,
    jwt
  }: {
    conductorRuleSet: EnrichedConductorRuleSet;
    jwt: string;
  }) => {
    await apiClient.updateConductorAdminRuleSet(conductorRuleSet, jwt);

    return conductorRuleSet;
  }
);

export const createRuleSet = createAsyncThunk(
  "conductorRuleSet/post",
  async ({
    conductorRuleSet,
    jwt
  }: {
    conductorRuleSet: EnrichedConductorRuleSet;
    jwt: string;
  }) => {
    await apiClient.createConductorAdminRuleSet(conductorRuleSet, jwt);

    return conductorRuleSet;
  }
);
