import { useDispatch, useSelector } from "react-redux";
import { TextInput } from "@interstate/components/TextInput";
import {
  clearErrorByKey,
  getAuthSettingsState,
  setAuthenticationRule
} from "../../slices/auth-settings";

export const AuthSettingsAuthenticationRulesOAuth = () => {
  const dispatch = useDispatch();
  const { authenticationRule } = useSelector(getAuthSettingsState);

  const handleBlur = (value: string) => {
    const updatedAuthenticationRule = {
      ...authenticationRule,
      oAuth2Details: {
        ...authenticationRule.oAuth2Details,
        clientId: value
      }
    };

    dispatch(setAuthenticationRule(updatedAuthenticationRule));

    if (value !== "" && authenticationRule.oAuth2Details?.clientId !== value) {
      dispatch(
        clearErrorByKey({ fieldName: "authenticationRules", key: "clientId" })
      );
    }
  };

  return (
    <TextInput
      id="oem-auth-settings-authentication-rules-input-clientId"
      label="Client ID"
      name="oAuth2Details.clientId"
      value={authenticationRule.oAuth2Details?.clientId}
      onBlur={event => handleBlur(event.target.value)}
    />
  );
};
