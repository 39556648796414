import { Tab, Tabs } from "@vinsolutions/core/cx";
import styled from "styled-components";
import { AccelerateActivityModalContentProps } from "../interfaces/accelerate-activity-modal-content-props";
import { DealDetailsModal } from "./deal-details-modal/deal-details-modal";
import ContentFrame from "./deal-details-modal/content-frame";
import { CARDASHBOARD_RIMS_BASEPATH } from "../constants/rims-frame-url";
import { useMemo } from "react";

export const BUYING_SIGNALS_SETTING_NAME =
  "Vin.Edison.BuyingSignals.keyinfomodal.url";

const StyledAccelerateActivityModalContent = styled.div`
  display: flex;
  flex-direction: column;
`;

const IFRAME_TITLE = "accelerateOnlineActivity";
const IFRAME_ID = "onlineActivitynew";

export function AccelerateActivityModalContent({
  customerId,
  dealerId,
  dealExchangeId,
  dealSettingsResponse,
  dealSummary,
  leadId
}: AccelerateActivityModalContentProps) {
  const isOnlineActivityOnly = dealExchangeId ? false : true;

  const contentFrameUrl = useMemo(() => {
    return customerId
      ? // eslint-disable-next-line @cspell/spellchecker
        `${CARDASHBOARD_RIMS_BASEPATH}?onlineActivityOnly=true&urlSettingName=${BUYING_SIGNALS_SETTING_NAME}&customerId=${customerId}&dealerId=${dealerId}&HideCloseButton=true&accelerateurl=%2FCarDashboard%2FPages%2Frims2.aspx`
      : "";
  }, [customerId, dealerId]);

  return (
    <StyledAccelerateActivityModalContent>
      {isOnlineActivityOnly ? (
        <ContentFrame
          iFrameId={IFRAME_ID}
          iFrameTitle={IFRAME_TITLE}
          iFrameUrl={contentFrameUrl}
        />
      ) : (
        <Tabs htmlId={"buying-signals-accelerate-modal"} type={"default"}>
          <Tab
            htmlId="buying-signals-accelerate-modal-accelerate-id"
            label="Accelerate"
          >
            <DealDetailsModal
              dealSettings={dealSettingsResponse}
              dealSummary={dealSummary}
              leadId={leadId}
            />
          </Tab>
          <Tab
            htmlId="buying-signals-accelerate-modal-online-activity-id"
            label="Online Activity"
          >
            <ContentFrame
              iFrameId={IFRAME_ID}
              iFrameTitle={IFRAME_TITLE}
              iFrameUrl={contentFrameUrl}
            />
          </Tab>
        </Tabs>
      )}
    </StyledAccelerateActivityModalContent>
  );
}
