import { useDispatch, useSelector } from "react-redux";
import { Switch } from "@interstate/components/Switch";
import { TextInput } from "@interstate/components/TextInput";
import { AuthSettingsAlertErrors } from "../auth-settings-alert-errors.component.tsx/authSettingsAlertErrors.component";
import { AuthSettingsPermissionsSelect } from "./authSettingsPermissionsSelect.component";
import {
  getAuthSettingsState,
  setPermission
} from "../../slices/auth-settings";

export const AuthSettingsPermissionsForm = ({
  isEditMode = false
}: {
  isEditMode?: boolean;
}) => {
  const dispatch = useDispatch();
  const { permission, validationErrors } = useSelector(getAuthSettingsState);

  const onHandleSwitchClick = () => {
    const updatedPermission = { ...permission, allowed: !permission.allowed };

    dispatch(setPermission(updatedPermission));
  };

  return (
    <>
      {validationErrors.permissions &&
        validationErrors.permissionsHasErrors && (
          <AuthSettingsAlertErrors errors={validationErrors.permissions} />
        )}
      {isEditMode ? (
        <TextInput
          disabled
          id="oem-auth-settings-permissions-input-action"
          label="Action"
          name=""
          size="sm"
          value={permission.action}
        />
      ) : (
        <AuthSettingsPermissionsSelect />
      )}
      <Switch
        checked={permission.allowed}
        label="Status"
        layout="vertical"
        onClick={onHandleSwitchClick}
      />
    </>
  );
};
