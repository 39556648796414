import { validateProps } from "./utils/validate-props";
import { OverlayRegistration } from "./interfaces/overlay-registration";
import { RegisterOverlayProps } from "./interfaces/register-overlay-props";

const overlayRegistry = new Map<string, OverlayRegistration<any>>();

/**
 * Registers an overlay.
 *
 * @template Props
 * @param {RegisterOverlayProps<Props>} params - The parameters for registering the overlay.
 */
export const registerOverlay = <Props>({
  name,
  component,
  requiredProps,
  showExpandButton,
  showSwapButton,
  showCloseButton
}: RegisterOverlayProps<Props>) => {
  const propsValidator = (props: any): props is Props => {
    return validateProps(props, requiredProps);
  };

  overlayRegistry.set(name, {
    component,
    propsValidator,
    showExpandButton: showExpandButton ?? true,
    showSwapButton: showSwapButton ?? true,
    showCloseButton: showCloseButton ?? true
  });
};

/**
 * Retrieves an overlay registration by name.
 *
 * @template Props
 * @param {Object} params - The parameters for retrieving the overlay.
 * @param {string} params.name - The name of the overlay.
 * @returns {OverlayRegistration<Props> | undefined} The overlay registration, or undefined if not found.
 */
export const getOverlay = <Props>({
  name
}: {
  name: string;
}): OverlayRegistration<Props> | undefined => {
  return overlayRegistry.get(name);
};
