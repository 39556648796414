import { Table } from "@interstate/components/Table";
import { Alert } from "@interstate/components/Alert";
import { PlusIcon } from "@interstate/components/Icons";
import { EnrichedPermission } from "../../types/authSettings.type";
import { ERROR_MSG_ON_DELETE } from "../../constants";
import { PermissionsTableColumnsConfig } from "./authSettingsPermissions.config";
import { AuthSettingsPermissionsActionButton } from "./authSettingsPermissionsActionButton.component";
import { generateId } from "../../helpers";

export const AuthSettingsPermissions = ({
  permissions,
  parentId
}: {
  permissions: EnrichedPermission[];
  parentId: string;
}) => {
  const defaultPermission = {
    action: "AddConductorLead",
    allowed: true,
    key: generateId(),
    parentId
  };

  return (
    <>
      <AuthSettingsPermissionsActionButton
        buttonIcon={<PlusIcon />}
        buttonLabel="Add Permission"
        initialPermission={defaultPermission}
      />
      <Table
        enablePagination
        columns={PermissionsTableColumnsConfig}
        data={permissions}
        defaultPageSize={10}
        emptyText="Not permissions found."
        headerBackgroundColor="light"
        id="oem-auth-settings-permissions-table"
        sortableDetails={{
          activeSortColumn: "action",
          sortOrder: "ascend",
          sortableColumns: ["action"]
        }}
      />
      {permissions.length === 1 && (
        <Alert id="oem-auth-settings-permissions-alert-message" type="caution">
          {ERROR_MSG_ON_DELETE.replace(/\[NAME\]/g, "permission")}
        </Alert>
      )}
    </>
  );
};
