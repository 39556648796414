import { TrashIcon } from "@interstate/components/Icons";
import { setModalContent } from "../../slices/auth-settings";
import { EnrichedConductorRuleSet } from "../../types/authSettings.type";
import { AuthSettingsButton } from "./authSettings.styles.component";
import { useAuthSettingsApp } from "../../hooks/useAuthSettings.hook";
import { useDispatch } from "react-redux";

export const AuthSettingsDeleteButton = (record: EnrichedConductorRuleSet) => {
  const dispatch = useDispatch();
  const { removeRuleSetByOem } = useAuthSettingsApp();

  const handleOnClick = () => {
    dispatch(
      setModalContent({
        isModalOpen: true,
        primary: {
          action: () => removeRuleSetByOem(record.oemSource.toString()),
          label: "Delete"
        },
        content: (
          <label>
            Are you sure you want to remove <b>{record.oemSource}</b>?
          </label>
        )
      })
    );
  };

  return (
    <AuthSettingsButton
      buttonStyle="secondary"
      id="oem-auth-settings-action-delete-oem"
      size="small"
      startIcon={<TrashIcon />}
      onClick={handleOnClick}
    >
      Delete
    </AuthSettingsButton>
  );
};
