import { useEffect } from "react";
import { AcquisitionLeadButtonPortalProps } from "../interfaces/acquisition-lead-button-portal-props";
import {
  TopPortalStyleProvider,
  usePortalsContext
} from "@vinsolutions/utility/portals";
import { useVinconnectFlagEnabled } from "@vinsolutions/core/third-party/launch-darkly";
import { Menu } from "@interstate/components/Menu";
import { MenuOption } from "@interstate/components/Menu";
import { useMenu } from "@interstate/components/Menu";
// eslint-disable-next-line @nx/enforce-module-boundaries
import { OverlayPosition } from "@vinsolutions/feature/overlays";

declare global {
  interface Window {
    LoadFrame: (framename: string, url: string) => void;
  }
}

export function AcquisitionLeadButton({
  customerId,
  originElement,
  portalId,
  portalEnabledCallback
}: AcquisitionLeadButtonPortalProps) {
  const { setPortalVisibility } = usePortalsContext();

  const portalFlagEnabled =
    useVinconnectFlagEnabled(
      "nx.vinconnect.vehicle-acquisition.add-lead.enable"
    ) && !!customerId;

  useEffect(() => {
    setPortalVisibility(portalId, portalFlagEnabled);

    if (portalEnabledCallback) {
      portalEnabledCallback(portalFlagEnabled);
    }
  }, [
    customerId,
    portalFlagEnabled,
    portalId,
    portalEnabledCallback,
    setPortalVisibility
  ]);

  const menuItems: MenuOption[] = [
    {
      label: "Add New Lead",
      value: "Add New Lead",
      onSelect: () =>
        window.LoadFrame(
          "leftpaneframe",
          `../../Pages/CRM/AddNewLeadForCustomer.aspx?GCID=${customerId}`
        )
    },
    {
      label: "Add New Acquisition Lead",
      value: "Add New Acquisition Lead",
      onSelect: () =>
        window.openOverlay("AcquisitionLeadAdd", OverlayPosition.LEFT, {
          customerId
        })
    }
  ];

  const menuReferenceProps = useMenu();

  useEffect(() => {
    const element = originElement.deref();

    if (element) {
      element.onclick = event => {
        // Convert the standard event to a React event if needed
        const reactEvent = event as unknown as React.MouseEvent<
          HTMLAnchorElement,
          MouseEvent
        >;
        menuReferenceProps.onClick(reactEvent);
      };

      menuReferenceProps.setAnchorEl(element);
    }
  }, [menuReferenceProps, originElement]);

  return (
    <>
      <span className="rtbOut">
        <span className="rtbMid">
          <span className="rtbIn">
            <img
              alt="Add New Lead"
              className="rtbIcon"
              src="/CarDashboard/RadControls/Toolbar/Skins/Mac/img/new.gif"
            />
          </span>
        </span>
      </span>
      <TopPortalStyleProvider cacheKey="acquisition-lead-button">
        <Menu
          id="AcquisitionLeadButtonMenu"
          menuOptions={menuItems}
          referenceProps={menuReferenceProps.referenceProps}
          size={"small"}
        />
      </TopPortalStyleProvider>
    </>
  );
}
