import {
  AuthSettingsErrors,
  EnrichedConductorRuleSet,
  EnrichedPermission
} from "../types/authSettings.type";

export const validateConductorPermission = (
  conductorRuleSet: EnrichedConductorRuleSet,
  conductorPermission: EnrichedPermission
): AuthSettingsErrors => {
  const errors: { [key: string]: string[] } = {};
  const permissionErrors: AuthSettingsErrors = { permissions: errors };

  const permissionExists = conductorRuleSet.permissions.some(
    permission =>
      permission.action === conductorPermission.action &&
      permission.key !== conductorPermission.key
  );

  if (permissionExists) {
    permissionErrors.permissionsHasErrors = true;
    errors.action = errors.action || [];
    errors.action.push(
      `Permission for action '${conductorPermission.action}' already exists.`
    );
  }

  return permissionErrors;
};
