import { Button } from "@vinsolutions/core-cx";
import styled from "styled-components";
import { ApplicationButtonProps } from "../../interfaces/deal-details-modal/application-button-props";

const StyledButton = styled(Button)`
  background: #06225c;
  border-color: #aeaeae;
  font-family: "Gotham";
  font-size: 16px;
  justify-content: flex-end;
  margin-left: 1em;
  width: 25%;
`;

const ApplicationButton = ({
  disabled = false,
  htmlId,
  label,
  name,
  onClick
}: ApplicationButtonProps) => {
  return (
    <StyledButton
      disabled={disabled}
      htmlId={htmlId}
      name={name}
      onClick={onClick}
    >
      {label}
    </StyledButton>
  );
};

export default ApplicationButton;
