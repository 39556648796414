import { FeatureEditVehicle } from "@vinsolutions/feature/edit-vehicle";
import { Box } from "@interstate/components/Box";
import { Spinner } from "@interstate/components/Spinner";
import { useCallback, useEffect, useState } from "react";
import {
  LeadInformation,
  VehicleInformation
} from "@vinsolutions/data-access/lead/crm.lead.bff";
import AddLeadCtas from "./components/lead-ctas";
import { useReactOidc } from "@axa-fr/react-oidc-context";
import { getProfileState } from "@vinsolutions/ccrm/store";
import { shallowEqual, useSelector } from "react-redux";
import { useNewRelicInteraction } from "@vinsolutions/core-third-party-newrelic";
import { AcquisitionLeadAddProps } from "./interfaces/acquisition-lead-add-props";
import { useLocation } from "react-router-dom";
import { FeatureEditLead } from "@vinsolutions/feature/edit-lead";
import styled from "styled-components";

const StyledLoader = styled.div`
  margin-top: 35%;
  flex-basis: 100%;
  text-align: center;
`;

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export function AcquisitionLeadAdd({
  customerId: stateCustomerId
}: AcquisitionLeadAddProps) {
  const query = useQuery();

  const { endInteraction, setInteractionAttributes } = useNewRelicInteraction(
    "PageAddLead",
    "Loading Add Lead"
  );

  const [customerId, setCustomerId] = useState<number>(stateCustomerId || 0);

  const [leadInformation, setLeadInformation] = useState<LeadInformation>({
    customerId,
    type: 1,
    source: 1,
    intent: 1
  } as LeadInformation);

  const [vehicleInformation, setVehicleInformation] =
    useState<VehicleInformation>({} as VehicleInformation);

  const { dealerId: profileDealerId } = useSelector(
    getProfileState,
    shallowEqual
  );
  const oidcUser = useReactOidc().oidcUser;

  if (!customerId) {
    const queryCustomerId = +(
      query.get("customerId") ||
      query.get("GlobalCustomerID") ||
      "0"
    );

    if (queryCustomerId > 0) {
      setCustomerId(queryCustomerId);
    } else {
      throw new Error("customerId parameter is required");
    }
  }

  const dealerId = profileDealerId || 0;

  setInteractionAttributes({
    customerId,
    dealerId
  });

  const handlePageLoad = useCallback(() => {
    endInteraction();
  }, [endInteraction]);

  // TODO: Not working, fix it
  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    window.addEventListener("load", handlePageLoad);

    return () => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      window.removeEventListener("load", handlePageLoad);
    };
  }, []);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  return isLoading && isLoading === true ? (
    <StyledLoader>
      <Spinner />
    </StyledLoader>
  ) : (
    <Box padding={"1em"}>
      <h2>Add Lead</h2>

      <FeatureEditLead
        dealerId={dealerId}
        jwt={oidcUser.access_token}
        leadInformation={leadInformation}
        setLeadInformation={setLeadInformation}
        showTitle={false}
      />

      <FeatureEditVehicle
        dealerId={dealerId}
        isAdding={true}
        jwt={oidcUser.access_token}
        setVehicleInformation={setVehicleInformation}
        vehicleInformation={vehicleInformation}
      />

      <AddLeadCtas
        customerId={customerId}
        dealerId={dealerId}
        jwt={oidcUser.access_token}
        leadInformation={leadInformation}
        setIsLoading={setIsLoading}
        vehicleInformation={vehicleInformation}
      />
    </Box>
  );
}
