import {
  PortalMessage,
  RegisteredPortal,
  RegisteredPortalFactory
} from "@vinsolutions/utility/portals";
import { PortalEventData } from "./interfaces/portal-event-data";
import { AcquisitionLeadButtonPortalProps } from "./interfaces/acquisition-lead-button-portal-props";
import { AcquisitionLeadButton } from "./components/acquisition-lead-button";

export class AcquisitionLeadButtonPortalFactory
  implements RegisteredPortalFactory<AcquisitionLeadButtonPortalProps>
{
  create({
    originDocument,
    originElement,
    portalId,
    portalKey,
    portalProps
  }: PortalMessage): RegisteredPortal<AcquisitionLeadButtonPortalProps> {
    const dataIsland = (portalProps?.data || {}) as PortalEventData;
    const customerId = dataIsland?.CustomerContext?.CustomerId;

    return {
      originDocument,
      originElement,
      portalId,
      portalKey,
      portalComponent: AcquisitionLeadButton,
      portalComponentProps: {
        customerId,
        originElement,
        portalId
      }
    };
  }
}
