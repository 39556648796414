import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useReactOidc } from "@axa-fr/react-oidc-context";
import { AuthSettingsComboBox } from "../../types/authSettings.type";
import { InterstateOnChangeEvent } from "@interstate/components/InterstateEvents";
import {
  clearErrorByKey,
  getAuthSettingsState,
  setOemSource
} from "../../slices/auth-settings";
import { coxAutoLeadOemApiClient as apiClient } from "@vinsolutions/data-access/oem/coxauto.lead.oem.api";
import {
  SelectInput,
  SelectInputEventValue
} from "@interstate/components/SelectInput";

const fetchAllOemSources = async (
  jwt: string
): Promise<AuthSettingsComboBox[]> => {
  try {
    const oemCollection = await apiClient.getAllOemSourcesAsync(jwt);

    return oemCollection
      .filter(oem => oem.isActive)
      .map(oem => ({
        label: oem.friendlyName,
        value: oem.name
      }));
  } catch (error) {
    return [];
  }
};

export const AuthSettingsOemSelect = () => {
  const dispatch = useDispatch();
  const { conductorRuleSets } = useSelector(getAuthSettingsState);
  const [options, setOptions] = useState<AuthSettingsComboBox[]>([]);
  const {
    oidcUser: { access_token: jwt }
  } = useReactOidc();

  const onChange = (event: InterstateOnChangeEvent<SelectInputEventValue>) => {
    const value = event.target.value?.toString() || "";

    dispatch(
      clearErrorByKey({ fieldName: "conductorRuleSet", key: "oemSource" })
    );
    dispatch(setOemSource(value));
  };

  useEffect(() => {
    fetchAllOemSources(jwt).then(comboboxSettings => {
      const filteredSettings = comboboxSettings
        .filter(
          setting =>
            !conductorRuleSets.some(
              ruleSet => ruleSet.oemSource === setting.value
            )
        )
        .sort((a, b) => a.label.localeCompare(b.label));

      setOptions(filteredSettings);
    });
  }, [conductorRuleSets, jwt]);

  return (
    <SelectInput
      displayDeselectOption={false}
      id="oem-auth-settings-oem-select"
      label="OEM"
      options={options}
      size="md"
      onChange={onChange}
    />
  );
};
