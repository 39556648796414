import styled from "styled-components";
import { ContentFrameProps } from "../../interfaces/deal-details-modal/content-frame-props";

const StyledContentFrame = styled.iframe`
  width: 100%;
  height: 800px;
  min-height: 800px;
  border: none;
`;

const ContentFrame = ({
  iFrameId,
  iFrameTitle,
  iFrameUrl
}: ContentFrameProps) => {
  return (
    iFrameUrl && (
      <StyledContentFrame id={iFrameId} src={iFrameUrl} title={iFrameTitle} />
    )
  );
};

export default ContentFrame;
