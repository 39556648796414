import { getBaseUrl } from "@vinsolutions/core/environment";
import { HttpRequest, fetchWrapper } from "@vinsolutions/core/http";
import { BASE_URL } from "./config/api.config";
import { createLogger } from "@vinsolutions/logger";
import { HttpStatusCode } from "axios";
import {
  ConductorRuleSet,
  OemSource,
  Permission
} from "./interfaces/conductorRuleSet.interface";
import { CoxAutoLeadOemApi } from "./interfaces/coxAutoLeadOemApi.interface";

const logger = createLogger("CoxAuto-Lead-OEM-Api");
const CONTENT_TYPE = "application/json";

const getHeaders = (jwt: string): Record<string, string> => {
  return {
    Authorization: `Bearer ${jwt}`,
    "content-type": CONTENT_TYPE
  };
};

export const coxAutoLeadOemApiClient: CoxAutoLeadOemApi = {
  async getAllConductorAdminRuleSetsAsync(
    jwt: string
  ): Promise<ConductorRuleSet[]> {
    const request: HttpRequest = {
      url: `${getBaseUrl(
        BASE_URL
      )}/api/conductor/admin/Ruleset/all?api-version=2`,
      method: "GET",
      headers: getHeaders(jwt)
    };

    const { data, statusCode, error, hasError } =
      await fetchWrapper.sendAsyncText(request);

    if (data != null && statusCode === HttpStatusCode.Ok) {
      return JSON.parse(data) as ConductorRuleSet[];
    } else {
      logger.error(
        `GetAll conductor admin rulesets failed with status: ${statusCode}\n${error}`
      );

      if (statusCode === HttpStatusCode.Unauthorized) {
        throw new Error("Unauthorized");
      }

      if (hasError) {
        throw new Error(error?.message);
      }

      return [];
    }
  },
  async deleteConductorAdminRuleSetByOemAsync(
    oem: string,
    jwt: string
  ): Promise<void> {
    const request: HttpRequest = {
      url: `${getBaseUrl(
        BASE_URL
      )}/api/conductor/admin/Ruleset/${oem}?api-version=2`,
      method: "DELETE",
      headers: getHeaders(jwt)
    };

    const { statusCode, error, hasError } = await fetchWrapper.sendAsyncText(
      request
    );

    if (statusCode !== HttpStatusCode.NoContent) {
      logger.error(
        `Delete conductor admin ruleset by oem failed with status: ${statusCode}\n${error}`
      );

      if (hasError) {
        throw new Error(error?.message);
      }
    }
  },
  async updateConductorAdminRuleSet(
    conductorRuleSet: ConductorRuleSet,
    jwt: string
  ): Promise<void> {
    const request: HttpRequest = {
      url: `${getBaseUrl(BASE_URL)}/api/conductor/admin/Ruleset?api-version=2`,
      method: "PUT",
      body: conductorRuleSet,
      headers: getHeaders(jwt)
    };

    const { statusCode, error, hasError } = await fetchWrapper.sendAsyncText(
      request
    );

    if (statusCode !== HttpStatusCode.NoContent) {
      logger.error(
        `Put conductor admin ruleset failed with status: ${statusCode}\n${error}`
      );

      if (hasError) {
        throw new Error(error?.message);
      }
    }
  },
  async getAllConductorAdminPermissionsAsync(
    jwt: string
  ): Promise<Permission[]> {
    const { data, statusCode, error } = await fetchWrapper.getAsync<
      Permission[]
    >(
      `${getBaseUrl(BASE_URL)}/api/conductor/admin/permissions/?api-version=2`,
      getHeaders(jwt)
    );

    if (data != null && statusCode === HttpStatusCode.Ok) {
      return data;
    } else {
      logger.error(
        `GetAll conductor admin permissions failed with status: ${statusCode}\n${error}`
      );

      return [];
    }
  },
  async getAllOemSourcesAsync(jwt: string): Promise<OemSource[]> {
    const { data, statusCode, error } = await fetchWrapper.getAsync<
      OemSource[]
    >(
      // eslint-disable-next-line @cspell/spellchecker
      `${getBaseUrl(BASE_URL)}/api/conductor/admin/oemsource/?api-version=2`,
      getHeaders(jwt)
    );

    if (data != null && statusCode === HttpStatusCode.Ok) {
      return data;
    } else {
      logger.error(
        `GetAll conductor admin oem sources failed with status: ${statusCode}\n${error}`
      );

      return [];
    }
  },
  async createConductorAdminRuleSet(
    conductorRuleSet: ConductorRuleSet,
    jwt: string
  ): Promise<void> {
    const request: HttpRequest = {
      url: `${getBaseUrl(BASE_URL)}/api/conductor/admin/Ruleset?api-version=2`,
      method: "POST",
      body: conductorRuleSet,
      headers: getHeaders(jwt)
    };

    const { statusCode, error, hasError } = await fetchWrapper.sendAsyncText(
      request
    );

    if (statusCode !== HttpStatusCode.NoContent) {
      logger.error(
        `create conductor admin ruleset failed with status: ${statusCode}\n${error}`
      );

      if (hasError) {
        throw new Error(error?.message);
      }
    }
  }
};
