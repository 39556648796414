import { Badge, BadgeVariant } from "@interstate/components/Badge";

export const AuthSettingsBadge = ({ disabled }: { disabled: boolean }) => {
  let name = "Active";
  let color: BadgeVariant = "success";

  if (disabled) {
    name = "Inactive";
    color = "light";
  }

  return (
    <Badge id={`auth-settings-status-${name}`} variant={color}>
      {name}
    </Badge>
  );
};
