import {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState
} from "react";
import { OverlayPosition } from "../enums/overlay-position";

declare global {
  interface Window extends OverlayContextType {
    doNothing: null;
  }
}

interface OverlayContextType {
  openOverlay: (
    overlayName: string,
    position: OverlayPosition,
    params?: any
  ) => void;

  closeOverlay: (overlayName: string) => void;

  switchOverlayPosition: (overlayName: string) => void;

  toggleOverlaySize: (overlayName: string) => void;

  activeOverlays: Map<
    string,
    { position: OverlayPosition; isFullScreen: boolean; params?: any }
  >;
}

const OverlayContext = createContext<OverlayContextType | undefined>(undefined);

/**
 * OverlayProvider component that provides overlay context to its children.
 *
 * @param {Object} props - The props for the provider.
 * @param {ReactNode} props.children - The child components that will have access to the overlay context.
 * @returns {JSX.Element} The provider component.
 */
export const OverlayProvider = ({
  children
}: {
  children: ReactNode;
}): JSX.Element => {
  const [activeOverlays, setActiveOverlays] = useState<
    Map<
      string,
      { position: OverlayPosition; isFullScreen: boolean; params?: any }
    >
  >(new Map());

  /**
   * Opens an overlay.
   *
   * @param {string} name - The name of the overlay.
   * @param {OverlayPosition} position - The position of the overlay.
   * @param {any} [params] - Optional parameters for the overlay.
   */
  const openOverlay = useCallback(
    (name: string, position: OverlayPosition, params?: any) => {
      setActiveOverlays(prev =>
        new Map(prev).set(name, { position, isFullScreen: false, params })
      );
    },
    []
  );

  /**
   * Closes an overlay.
   *
   * @param {string} name - The name of the overlay.
   */
  const closeOverlay = useCallback((name: string) => {
    setActiveOverlays(prev => {
      const newMap = new Map(prev);
      newMap.delete(name);
      return newMap;
    });
  }, []);

  /**
   * Switches the position of an overlay.
   *
   * @param {string} name - The name of the overlay.
   */
  const switchOverlayPosition = useCallback((name: string) => {
    setActiveOverlays(prev => {
      const newMap = new Map(prev);
      const overlay = newMap.get(name);
      if (overlay) {
        newMap.set(name, {
          ...overlay,
          position:
            overlay.position === OverlayPosition.LEFT
              ? OverlayPosition.RIGHT
              : OverlayPosition.LEFT
        });
      }
      return newMap;
    });
  }, []);

  /**
   * Toggles the size of an overlay between full screen and its default size.
   *
   * @param {string} name - The name of the overlay.
   */
  const toggleOverlaySize = useCallback((name: string) => {
    setActiveOverlays(prev => {
      const newMap = new Map(prev);
      const overlay = newMap.get(name);
      if (overlay) {
        newMap.set(name, { ...overlay, isFullScreen: !overlay.isFullScreen });
      }
      return newMap;
    });
  }, []);

  const contextValue = useMemo(
    () => ({
      openOverlay,
      closeOverlay,
      switchOverlayPosition,
      toggleOverlaySize,
      activeOverlays
    }),
    [
      openOverlay,
      closeOverlay,
      switchOverlayPosition,
      toggleOverlaySize,
      activeOverlays
    ]
  );

  return (
    <OverlayContext.Provider value={contextValue}>
      {children}
    </OverlayContext.Provider>
  );
};

/**
 * Custom hook to use the overlay context.
 *
 * @throws Will throw an error if used outside of an OverlayProvider.
 * @returns {OverlayContextType} The overlay context.
 */
export const useOverlay = (): OverlayContextType => {
  const context = useContext(OverlayContext);

  if (!context) {
    throw new Error("useOverlay must be used within an OverlayProvider");
  }

  return context;
};
