import { useDispatch, useSelector } from "react-redux";
import { useReactOidc } from "@axa-fr/react-oidc-context";
import {
  createRuleSet,
  deleteRuleSetByOem,
  fetchAllRuleSets,
  getAuthSettingsState,
  updateRuleSet
} from "../slices/auth-settings";
import { EnrichedConductorRuleSet } from "../types/authSettings.type";
import { AnyAction } from "redux";

export const useAuthSettingsApp = () => {
  const {
    oidcUser: { access_token: jwt }
  } = useReactOidc();
  const dispatch = useDispatch();

  const getAllRuleSets = () => {
    dispatch(fetchAllRuleSets({ jwt }) as unknown as AnyAction);
  };

  const putRuleSet = (conductorRuleSet: EnrichedConductorRuleSet) => {
    dispatch(updateRuleSet({ jwt, conductorRuleSet }) as unknown as AnyAction);
  };

  const postRuleSet = (conductorRuleSet: EnrichedConductorRuleSet) => {
    dispatch(createRuleSet({ jwt, conductorRuleSet }) as unknown as AnyAction);
  };

  const removeRuleSetByOem = (oem: string) => {
    dispatch(deleteRuleSetByOem({ oem, jwt }) as unknown as AnyAction);
  };

  return {
    getAllRuleSets,
    postRuleSet,
    putRuleSet,
    removeRuleSetByOem
  };
};

export const useConductorRuleSet = (key: string) => {
  const { conductorRuleSet, conductorRuleSets } =
    useSelector(getAuthSettingsState);

  let ruleSet: EnrichedConductorRuleSet | undefined;

  if (conductorRuleSet.key === key) {
    ruleSet = conductorRuleSet;
  } else {
    ruleSet = conductorRuleSets.find(ruleSet => ruleSet.key === key);
  }

  return { conductorRuleSet: ruleSet };
};
