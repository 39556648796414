import { Alert } from "@interstate/components/Alert";
import { AuthSettingsRedList } from "./authSettingsAlertErrors.styles.component";

export const AuthSettingsAlertErrors = (errors: {
  [category: string]: { [field: string]: string[] };
}) => {
  return (
    <Alert
      title={`Please address the following issues`}
      titleSx={{ color: "#d41e27 !important", fontSize: "15px" }}
      type="error"
    >
      {Object.entries(errors).map(([category, fields]) => (
        <div key={category}>
          {Object.entries(fields).map(([field, errorMessages]) => (
            <AuthSettingsRedList
              id={`oem-auth-settings-alert-errors-${field}`}
              key={field}
            >
              {errorMessages.join(". ")}
            </AuthSettingsRedList>
          ))}
        </div>
      ))}
    </Alert>
  );
};
