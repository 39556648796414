import { getBaseUrl } from "@vinsolutions/core/environment";
import { fetchWrapper } from "@vinsolutions/core/http";
import { createLogger } from "@vinsolutions/logger";
import { DealApiConfig } from "./crm.workflow.deal.api.config";
import { DealSettingsResponse } from "./models/deal-settings-response.model";

const logger = createLogger("data-access-workflow-crm.workflow.deal.api");

export const getDealSettings = async (
  leadId: number,
  dealerId: number,
  jwt: string
): Promise<DealSettingsResponse | null | undefined> => {
  if (leadId <= 0 || dealerId <= 0) {
    logger.error(
      "Error getting deal settings.",
      "The leadId and dealerId must have values ​​greater than 0."
    );
    return null;
  }
  const dealSettingsUrl = getDealSettingsUrl(leadId, dealerId);
  const response = await fetchWrapper.getAsync<DealSettingsResponse>(
    dealSettingsUrl,
    {
      Authorization: `Bearer ${jwt}`,
      Accept: "application/vnd.coxauto.v1+json",
      "Content-Type": "application/vnd.coxauto.v1+json"
    }
  );

  switch (response.statusCode) {
    case 200:
      return response.data;
    case 404:
      return null;
    default: {
      if (response.statusCode === 403) {
        logger.debug("", JSON.stringify(response));
      } else {
        logger.error("Error getting deal settings.", JSON.stringify(response));
      }
      return null;
    }
  }
};

export function getDealSettingsUrl(leadId?: number, dealerId?: number): string {
  const baseUrl = getBaseUrl(DealApiConfig.baseUrls);
  return `${baseUrl}from/digital-retail/deal/settings?leadId=${leadId}&dealerId=${dealerId}`;
}
