import { useEffect, useState } from "react";
import { useOverlay } from "../context/overlay-context";
import { getOverlay } from "../vinconnect-overlay-register";
import useOverlayEvents from "../hooks/use-overlay-events";
import { XMarkIcon } from "@interstate/components/Icons/XMarkIcon";
import { ArrowsPointingInIcon } from "@interstate/components/Icons/ArrowsPointingInIcon";
import { ArrowsPointingOutIcon } from "@interstate/components/Icons/ArrowsPointingOutIcon";
import { ArrowsRightLeftIcon } from "@interstate/components/Icons/ArrowsRightLeftIcon";
import { Button } from "@interstate/components/Button";
import { Box } from "@interstate/components/Box";
import styled from "styled-components";
import { createLogger } from "@vinsolutions/logger";
import { useLocation } from "react-router-dom";
import { OverlayPosition } from "../enums/overlay-position";

const logger = createLogger("feature-overlays");

const StyledOverlay = styled.div`
  grid-row: 2/3;
  background-color: #fff;
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: min-content;
  z-index: 1;
  overflow: auto;

  &.left-overlay {
    grid-column: 1/2;
  }

  &.right-overlay {
    grid-column: 2/3;
  }

  &.full-screen {
    grid-column: 1 / -1;
  }
`;

interface FrameChangeEventDetail {
  frameId: string;
  url: string;
}

/**
 * VinConnectOverlays component renders and manages multiple overlays.
 * It uses the overlay context to handle the state and actions for overlays.
 *
 * @component
 * @example
 * return (
 *   <VinConnectOverlays />
 * )
 */
export const VinConnectOverlays = () => {
  const location = useLocation();

  const {
    activeOverlays,
    closeOverlay,
    switchOverlayPosition,
    toggleOverlaySize
  } = useOverlay();

  const [activeOverlay, setActiveOverlay] = useState<string | null>(null);

  useOverlayEvents();

  const handleOverlayAction = (
    name: string,
    action: () => void,
    isCloseAction = false
  ) => {
    if (isCloseAction) {
      setActiveOverlay(null);
    } else {
      setActiveOverlay(name);
    }
    action();
  };

  // if location changes, close all overlays
  useEffect(() => {
    activeOverlays.forEach((_, name) => {
      handleOverlayAction(name, () => closeOverlay(name), true);
    });
  }, [location]);

  useEffect(() => {
    const handleFrameChangeEvent = ({
      detail: { frameId: changedFrameId }
    }: CustomEvent<FrameChangeEventDetail>) => {
      activeOverlays.forEach((overlay, name) => {
        const overlayIsOnChangedFrameSide =
          changedFrameId === "leftpaneframe"
            ? overlay.position === OverlayPosition.LEFT
            : overlay.position === OverlayPosition.RIGHT;

        // Close all frames that are positioned on the same side as the pane that changed
        if (overlayIsOnChangedFrameSide) {
          handleOverlayAction(name, () => closeOverlay(name), true);

          return;
        }

        // If overlays is in fullscreen mode then shrink it
        if (overlay.isFullScreen) {
          handleOverlayAction(name, () => toggleOverlaySize(name));
        }
      });
    };

    window.addEventListener(
      "paneFrameChange",
      handleFrameChangeEvent as EventListener,
      true
    );

    return () => {
      window.removeEventListener(
        "paneFrameChange",
        handleFrameChangeEvent as EventListener,
        true
      );
    };
  }, [activeOverlays, closeOverlay, toggleOverlaySize]);

  const renderOverlay = (name: string) => {
    const overlayRegistration = getOverlay({ name });
    const overlayState = activeOverlays.get(name);

    if (!overlayRegistration || !overlayState) {
      return null;
    }

    const {
      component: overlayComponent,
      propsValidator,
      showExpandButton,
      showSwapButton,
      showCloseButton
    } = overlayRegistration;

    if (propsValidator && !propsValidator(overlayState.params)) {
      logger.error(`Invalid props for overlay: ${name}`, overlayState.params);

      return null;
    }

    const className = `${overlayState.position}-overlay ${
      overlayState.isFullScreen ? "full-screen" : ""
    }`;

    return (
      <StyledOverlay className={className} key={name}>
        {(showCloseButton || showSwapButton || showExpandButton) && (
          <Box
            display={"flex"}
            height={"0px"}
            justifyContent={"flex-end"}
            width={"100%"}
          >
            {showSwapButton && !overlayState.isFullScreen && (
              <Button
                buttonStyle="tertiary"
                icon={<ArrowsRightLeftIcon color={"grey"} />}
                size="small"
                onClick={() =>
                  handleOverlayAction(name, () => switchOverlayPosition(name))
                }
              />
            )}
            {showExpandButton && (
              <Button
                buttonStyle="tertiary"
                icon={
                  overlayState.isFullScreen ? (
                    <ArrowsPointingInIcon color={"grey"} />
                  ) : (
                    <ArrowsPointingOutIcon color={"grey"} />
                  )
                }
                size="small"
                onClick={() =>
                  handleOverlayAction(name, () => toggleOverlaySize(name))
                }
              />
            )}
            {showCloseButton && (
              <Button
                buttonStyle="tertiary"
                icon={<XMarkIcon />}
                size="small"
                onClick={() =>
                  handleOverlayAction(name, () => closeOverlay(name), true)
                }
              />
            )}
          </Box>
        )}
        <div className="overlay-content">
          {overlayComponent(overlayState.params)}
        </div>
      </StyledOverlay>
    );
  };

  return (
    <>
      {Array.from(activeOverlays.keys())
        .filter(name => name !== activeOverlay)
        .map(name => renderOverlay(name))}
      {activeOverlay && renderOverlay(activeOverlay)}
    </>
  );
};

export default VinConnectOverlays;
