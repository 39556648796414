import { ApplicationLabelProps } from "../../interfaces/deal-details-modal/application-label-props";

const ApplicationLabel = ({ icon, labelText }: ApplicationLabelProps) => {
  return (
    <span>
      {icon}
      {icon && labelText && <br />}
      <span>{labelText}</span>
    </span>
  );
};

export default ApplicationLabel;
