import { useSelector } from "react-redux";
import { Grid } from "@interstate/components/Grid";
import { Card } from "@interstate/components/Card";
import { getAuthSettingsState } from "../../slices/auth-settings";
import { AuthSettingsOemSelect } from "./authSettingsOemSelect.component";
import { AuthSettingsPermissions } from "../auth-settings-permissions/authSettingsPermissions.component";
import { AuthSettingsAlertErrors } from "../auth-settings-alert-errors.component.tsx/authSettingsAlertErrors.component";
import { AuthSettingsAuthenticationRules } from "../auth-settings-authentication-rules/authSettingsAuthenticationRules.component";

export const AuthSettingsForm = () => {
  const { conductorRuleSet, validationErrors } =
    useSelector(getAuthSettingsState);

  return (
    <Card
      content={
        <Grid container columnSpacing={1} rowSpacing={1}>
          <Grid xs={6}>
            <AuthSettingsOemSelect />
          </Grid>
          <Grid xs={12}>
            <h4>Authentication Rules</h4>
            <AuthSettingsAuthenticationRules
              authenticationRules={conductorRuleSet.authenticationRules}
              parentId={conductorRuleSet.key}
            />
          </Grid>
          <Grid xs={12}>
            <h4>Permissions</h4>
            <AuthSettingsPermissions
              parentId={conductorRuleSet.key}
              permissions={conductorRuleSet.permissions}
            />
          </Grid>
        </Grid>
      }
      header={
        validationErrors.conductorRuleSet &&
        validationErrors.conductorRuleSetHasErrors && (
          <AuthSettingsAlertErrors errors={validationErrors.conductorRuleSet} />
        )
      }
    />
  );
};
