import { useCallback, useEffect } from "react";
import { EditLeadProps } from "./interfaces/edit-lead-props";
import EditLeadLeadInformation from "./components/lead-information";
import { getLeadInformation } from "@vinsolutions/data-access/lead/crm.lead.bff";
import EditLeadCtas from "./components/lead-ctas";
import styled from "styled-components";

/**
 * Hides a third div that throws off styling. This will be fixed in Interstate 1.1.0, so this can potentially be removed after that version
 */
const InterstateInputStyleFix = styled.div`
  &&& div.ids-text-input-container.MuiBox-root,
  div.ids-combo-box-root-container.MuiBox-root,
  div.interstate-select-input-root.MuiBox-root {
    div.MuiGrid2-container
      div.MuiGrid2-root.MuiGrid2-direction-xs-row:nth-child(3) {
      display: none;
    }
  }
`;

/**
 * The FeatureEditVehicle for adding/updating a vehicle for a lead. If leadId is provided,
 * vehicle information will try to be retrieve and presented for editing, otherwise the
 * component assumes it is adding a vehicle and returns it for saving along with a new lead.
 */
export function FeatureEditLead({
  dealerId,
  leadId,
  leadInformation,
  jwt,
  setLeadInformation,
  showTitle,
  onClose
}: EditLeadProps) {
  const loadLead = useCallback(async () => {
    const result =
      jwt && leadId && leadId > 0
        ? await getLeadInformation(leadId, jwt, true)
        : null;

    if (result) {
      setLeadInformation(result);
    }
  }, [leadId, jwt, setLeadInformation]);

  useEffect(() => {
    loadLead();
  }, [loadLead, leadId]);

  return (
    <InterstateInputStyleFix>
      {showTitle && <h2>Edit Lead</h2>}

      <EditLeadLeadInformation
        dealerId={dealerId}
        jwt={jwt}
        leadInformation={leadInformation}
        setLeadInformation={setLeadInformation}
      />

      {leadInformation?.id && leadInformation.id > 0 && (
        <EditLeadCtas
          dealerId={dealerId}
          jwt={jwt}
          leadInformation={leadInformation}
          onClose={onClose}
        />
      )}
    </InterstateInputStyleFix>
  );
}

export default FeatureEditLead;
