import { BaseUrlsObj, envEnum } from "@vinsolutions/core/environment";

type Config = {
  baseUrls: BaseUrlsObj;
};

export const DeskingApiConfig: Config = {
  baseUrls: {
    [envEnum.LOCAL]: "http://swbdealdv-qts01.vin.priv/",
    [envEnum.DEV]: "http://swbdealdv-qts01.vin.priv/",
    [envEnum.QA]: "https://qa-desking.vinsolutions.com/",
    [envEnum.STAGING]: "https://staging-desking.vinsolutions.com/",
    [envEnum.PRODUCTION]: "https://desking.vinsolutions.com/"
  }
};
